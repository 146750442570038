export default {
    namespaced: true,
    state: () => ({
        ThongTinLuongTuyen: {
            TinhDi: null,
            BenDi: null,
            TinhDen: null,
            BenDen: null,
            MaTuyen: null,
            LoaiTuyen: null,
            HanhTrinhChayDi: null,
            CuLyDi: null,
            HanhTrinhChayDen: null,
            CuLyDen: null,
            DanhSachGioXuatBenDi: [],
            DanhSachDiemDungDi: [],
            DanhSachGioXuatBenDen: [],
            DanhSachDiemDungDen: [],
            CheckThietLapTuyen: false,
            NhaXe: null,
        },
        DanhSachLuongTuyen: [],
        DanhSachLoaiTuyen: [],
        ThongTinCapTuyen: {
            LuongTuyen: null,
            DanhSachXeSelect: [],
            DanhSachXeTable: [],
        },
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        Reload_DanhSachLuongTuyen(store, { TextTimKiem, query, key }) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        if (TextTimKiem) {
                            loadOptions.filter = [
                                ["MaTuyen", "contains", TextTimKiem],
                                "or",
                                ["TenTuyen", "contains", TextTimKiem],
                                "or",
                                ["LoaiTuyen", "contains", TextTimKiem],
                                "or",
                                ["TrangThai", "contains", TextTimKiem],
                            ];
                        }
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachLuongTuyenTable"
                            )
                        ).Post({
                            loadOptions: loadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async Get_ChiTietLuongTuyen(store, idLuongTuyen) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.ChiTietLuongTuyen"),
                    {
                        idLuongTuyen: idLuongTuyen,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.Data.status === true) {
                    let DanhSachGioXuatBenDi = result.LoTrinhDi.GioXuatBens.map(
                        (e) => {
                            e.GioXuatBen =
                                this.$Helper.Common.formatViewDateToTimeZone7(
                                    e.GioXuatBen
                                );
                            return e;
                        }
                    );
                    let DanhSachGioXuatBenDen =
                        result.LoTrinhVe.GioXuatBens.map((e) => {
                            e.GioXuatBen =
                                this.$Helper.Common.formatViewDateToTimeZone7(
                                    e.GioXuatBen
                                );
                            return e;
                        });
                    let ThongTinLuongTuyen = {
                        TinhDi: result.IdTinhDi,
                        BenDi: result.IdBenDi,
                        TinhDen: result.IdTinhDen,
                        BenDen: result.IdBenDen,
                        MaTuyen: result.MaTuyen,
                        LoaiTuyen: result.IdLoaiTuyen,
                        HanhTrinhChayDi: result.LoTrinhDi.HanhTrinhChay,
                        NoiKetThucDi: result.LoTrinhDi.NoiKetThuc,
                        NoiXuatPhatDi: result.LoTrinhDi.NoiXuatPhat,
                        CuLyDi: result.LoTrinhDi.CuLy,
                        HanhTrinhChayDen: result.LoTrinhVe.HanhTrinhChay,
                        NoiKetThucDen: result.LoTrinhVe.NoiKetThuc,
                        NoiXuatPhatDen: result.LoTrinhVe.NoiXuatPhat,
                        CuLyDen: result.LoTrinhVe.CuLy,
                        DanhSachGioXuatBenDi: DanhSachGioXuatBenDi,
                        DanhSachDiemDungDi: result.LoTrinhDi.DiemDungNghis,
                        DanhSachGioXuatBenDen: DanhSachGioXuatBenDen,
                        DanhSachDiemDungDen: result.LoTrinhVe.DiemDungNghis,
                        CheckThietLapTuyen: false,
                        NhaXe: result.DanhSachIdNhaXe,
                    };
                    set(store, "ThongTinLuongTuyen", ThongTinLuongTuyen);
                    return resp.Data;
                } else {
                    ResetThongTinLuongTuyen(store);
                    return resp.Data;
                }
            } catch (ex) {
                console.log("🚀 Get_ChiTietLuongTuyen ~ ex", ex);
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async ThemLuongTuyen(store) {
            let ThongTinLuongTuyen = store.state.ThongTinLuongTuyen;
            try {
                this.startLoading();
                let DanhSachGioXuatBenDi =
                    ThongTinLuongTuyen.DanhSachGioXuatBenDi.map((e) => {
                        e.GioXuatBen =
                            this.$Helper.Common.formatDateToTimeZone7(
                                e.GioXuatBen
                            );
                        return e;
                    });
                let DanhSachGioXuatBenDen =
                    ThongTinLuongTuyen.DanhSachGioXuatBenDen.map((e) => {
                        e.GioXuatBen =
                            this.$Helper.Common.formatDateToTimeZone7(
                                e.GioXuatBen
                            );
                        return e;
                    });
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.ThemLuongTuyen")
                ).Post({
                    idTinhDi: ThongTinLuongTuyen.TinhDi,
                    idTinhDen: ThongTinLuongTuyen.TinhDen,
                    idBenDi: ThongTinLuongTuyen.BenDi,
                    idBenDen: ThongTinLuongTuyen.BenDen,
                    idLoaiTuyen: ThongTinLuongTuyen.LoaiTuyen,
                    maTuyen: ThongTinLuongTuyen.MaTuyen,
                    loTrinhDi: {
                        hanhTrinhChay: ThongTinLuongTuyen.HanhTrinhChayDi,
                        noiXuatPhat: ThongTinLuongTuyen.NoiXuatPhatDi,
                        noiKetThuc: ThongTinLuongTuyen.NoiKetThucDi,
                        cuLy: ThongTinLuongTuyen.CuLyDi,
                        gioXuatBens: DanhSachGioXuatBenDi,
                        diemDungNghis: ThongTinLuongTuyen.DanhSachDiemDungDi,
                    },
                    loTrinhVe: {
                        hanhTrinhChay: ThongTinLuongTuyen.HanhTrinhChayDen,
                        noiXuatPhat: ThongTinLuongTuyen.NoiXuatPhatDen,
                        noiKetThuc: ThongTinLuongTuyen.NoiKetThucDen,
                        cuLy: ThongTinLuongTuyen.CuLyDen,
                        gioXuatBens: DanhSachGioXuatBenDen,
                        diemDungNghis: ThongTinLuongTuyen.DanhSachDiemDungDen,
                    },
                    DanhSachIdNhaXe: ThongTinLuongTuyen.NhaXe || [],
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLuongTuyen ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        async SuaLuongTuyen(store, idLuongTuyen) {
            let ThongTinLuongTuyen = store.state.ThongTinLuongTuyen;
            try {
                this.startLoading();
                let DanhSachGioXuatBenDi =
                    ThongTinLuongTuyen.DanhSachGioXuatBenDi.map((e) => {
                        e.GioXuatBen =
                            this.$Helper.Common.formatDateToTimeZone7(
                                e.GioXuatBen
                            );
                        return e;
                    });
                let DanhSachGioXuatBenDen =
                    ThongTinLuongTuyen.DanhSachGioXuatBenDen.map((e) => {
                        e.GioXuatBen =
                            this.$Helper.Common.formatDateToTimeZone7(
                                e.GioXuatBen
                            );
                        return e;
                    });
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.SuaLuongTuyen")
                ).Post({
                    idLuongTuyen: idLuongTuyen,
                    idTinhDi: ThongTinLuongTuyen.TinhDi,
                    idTinhDen: ThongTinLuongTuyen.TinhDen,
                    idBenDi: ThongTinLuongTuyen.BenDi,
                    idBenDen: ThongTinLuongTuyen.BenDen,
                    idLoaiTuyen: ThongTinLuongTuyen.LoaiTuyen,
                    maTuyen: ThongTinLuongTuyen.MaTuyen,
                    loTrinhDi: {
                        hanhTrinhChay: ThongTinLuongTuyen.HanhTrinhChayDi,
                        noiXuatPhat: ThongTinLuongTuyen.NoiXuatPhatDi,
                        noiKetThuc: ThongTinLuongTuyen.NoiKetThucDi,
                        cuLy: ThongTinLuongTuyen.CuLyDi,
                        gioXuatBens: DanhSachGioXuatBenDi,
                        diemDungNghis: ThongTinLuongTuyen.DanhSachDiemDungDi,
                    },
                    loTrinhVe: {
                        hanhTrinhChay: ThongTinLuongTuyen.HanhTrinhChayDen,
                        noiXuatPhat: ThongTinLuongTuyen.NoiXuatPhatDen,
                        noiKetThuc: ThongTinLuongTuyen.NoiKetThucDen,
                        cuLy: ThongTinLuongTuyen.CuLyDen,
                        gioXuatBens: DanhSachGioXuatBenDen,
                        diemDungNghis: ThongTinLuongTuyen.DanhSachDiemDungDen,
                    },
                    DanhSachIdNhaXe: ThongTinLuongTuyen.NhaXe,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLuongTuyen ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        async XoaLuongTuyen(store, idLuongTuyen) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.XoaLuongTuyen")
                ).Post({
                    idLuongTuyen: idLuongTuyen,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLuongTuyen ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        async CapTuyen(store) {
            let ThongTinCapTuyen = store.state.ThongTinCapTuyen;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.CapTuyenChoXe")
                ).Post({
                    idLuongTuyen: ThongTinCapTuyen.LuongTuyen,
                    danhSachXe: ThongTinCapTuyen.DanhSachXeTable,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLuongTuyen ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        async Get_DanhSachLuongTuyen(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t(
                        "urlQLTTLenh.DanhSachLuongTuyenCapTuyen"
                    )
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    set(store, "DanhSachLuongTuyen", result || []);
                } else {
                    set(store, "DanhSachLuongTuyen", []);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachPhuongTienTrenTuyen(store) {
            let ThongTinCapTuyen = store.state.ThongTinCapTuyen;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t(
                        "urlQLTTLenh.DanhSachXeHoatDongTrenTuyen"
                    ),
                    {
                        idLuongTuyen: ThongTinCapTuyen.LuongTuyen,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    ThongTinCapTuyen.DanhSachXeTable = result || [];
                } else {
                    ThongTinCapTuyen.DanhSachXeTable = [];
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachLoaiTuyen(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachLoaiTuyen")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    set(store, "DanhSachLoaiTuyen", result || []);
                } else {
                    set(store, "DanhSachLoaiTuyen", []);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async GetMaTuyenGoiY(store, MaTuyen) {
            let ThongTinCapTuyen = store.state.ThongTinCapTuyen;
            try {
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.GoiYMaTuyen"),
                    {
                        MaTuyen: MaTuyen,
                    }
                ).Get();
                return resp.Data;
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        ResetThongTinLuongTuyen(store) {
            ResetThongTinLuongTuyen(store);
        },
        ResetThongTinCapTuyen(store) {
            ResetThongTinCapTuyen(store);
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
function ResetThongTinLuongTuyen(store) {
    let ThongTinLuongTuyen = {
        TinhDi: null,
        BenDi: null,
        TinhDen: null,
        BenDen: null,
        MaTuyen: null,
        HanhTrinhChayDi: null,
        CuLyDi: null,
        HanhTrinhChayDen: null,
        CuLyDen: null,
        DanhSachGioXuatBenDi: [],
        DanhSachDiemDungDi: [],
        DanhSachGioXuatBenDen: [],
        DanhSachDiemDungDen: [],
        CheckThietLapTuyen: false,
        NhaXe: null,
    };
    store.commit("Set", {
        key: "ThongTinLuongTuyen",
        data: ThongTinLuongTuyen,
    });
}

function ResetThongTinCapTuyen(store) {
    let ThongTinCapTuyen = {
        LuongTuyen: null,
        DanhSachXeSelect: [],
        DanhSachXeTable: [],
    };
    store.commit("Set", {
        key: "ThongTinCapTuyen",
        data: ThongTinCapTuyen,
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
