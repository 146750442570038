export default {
    namespaced: true,
    state: () => ({
        ThongTinPhuongTien: {
            BienKiemSoat: null,
            DVVT: null,
            SoKhung: null,
            SoMay: null,
            LoaiXe: null,
            SoCho: null,
            SoGhe: null,
            SoGiuong: null,
            IdSoDoCho: null,
            SoDangKyXe: null,
            HanBaoHiem: null,
            HanDangKiem: null,
            HanBienNhan: null,
            ChieuDai: null,
            ChieuRong: null,
            ChieuCao: null,
            HangGSHT: null,
            TaiKhoan: null,
            NhaXe: [],
            MatKhau: null,
            DanhSachHinhAnh_Push: [],
            DanhSachHinhAnh_View: [],
            DanhSachHinhAnh_Details: [],
        },
        DanhSachLoaiXe: [],
        DanhSachHangGSHT: [],
        DanhSachPhuongTien: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        Reload_DanhSachPhuongTien(store, { TextTimKiem, query, key }) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        if (TextTimKiem) {
                            loadOptions.filter = [
                                ["BienKiemSoat", "contains", TextTimKiem],
                                "or",
                                ["TrangThai", "contains", TextTimKiem],
                            ];
                        }
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachPhuongTienTable"
                            )
                        ).Post({
                            loadOptions: loadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async Get_DanhSachLoaiXe(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachLoaiXe")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    set(store, "DanhSachLoaiXe", result || []);
                } else {
                    set(store, "DanhSachLoaiXe", []);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachHangGSHT(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachHangGSHT")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    set(store, "DanhSachHangGSHT", result || []);
                } else {
                    set(store, "DanhSachHangGSHT", []);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachPhuongTien(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachXeCapTuyen")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    set(store, "DanhSachPhuongTien", result || []);
                } else {
                    set(store, "DanhSachPhuongTien", []);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_ChiTietPhuongTien(store, idPhuongTien) {
            let ThongTinPhuongTienDefault = store.state.ThongTinPhuongTien;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.ChiTietPhuongTien"),
                    {
                        idXe: idPhuongTien,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.Data.status === true && result) {
                    let ThongTinPhuongTien = {
                        BienKiemSoat: result.BienKiemSoat,
                        DVVT: result.TenDonVi || ThongTinPhuongTienDefault.DVVT,
                        SoKhung: result.SoKhung,
                        SoMay: result.SoMay,
                        LoaiXe: result.IdLoaiXe,
                        SoCho: result.SoCho,
                        SoGhe: result.SoGhe,
                        SoGiuong: result.SoGiuong,
                        NhaXe: result.DanhSachIdNhaXe,

                        IdSoDoCho: result.IdLoaiSoDo,
                        SoDangKyXe: result.SoDangKy || null,
                        HanBaoHiem: new Date(result.HanBaoHiem),
                        HanDangKiem: new Date(result.HanDangKiem),
                        HanBienNhan: result.HanBienBanTheChap
                            ? new Date(result.HanBienBanTheChap)
                            : null,
                        ChieuDai: result.KhoangChuaHang_ChieuDai,
                        ChieuRong: result.KhoangChuaHang_ChieuRong,
                        ChieuCao: result.KhoangChuaHang_ChieuCao,
                        HangGSHT: result.IdHangGSHT,
                        TaiKhoan: result.GSHT_TaiKhoan,
                        MatKhau: result.GSHT_MatKhau,
                        DanhSachHinhAnh_Push: [],
                        DanhSachHinhAnh_View: [],
                        DanhSachHinhAnh_Details: result.HinhAnhs,
                    };
                    ThongTinPhuongTien.DanhSachHinhAnh_Details.forEach((e) => {
                        ThongTinPhuongTien.DanhSachHinhAnh_View.push(
                            SP["API_QuanLyThongTinLenh"] +
                                this.$i18n.global.t("urlQLTTLenh.HinhAnhXe") +
                                "?idHinhAnh=" +
                                e
                        );
                    });
                    set(store, "ThongTinPhuongTien", ThongTinPhuongTien);
                    return resp.Data;
                } else {
                    ResetThongTinPhuongTien(store);
                    return resp.Data;
                }
            } catch (ex) {
                console.log("🚀 Get_ChiTietPhuongTien ~ ex", ex);
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async ThemPhuongTien(store) {
            let ThongTinPhuongTien = store.state.ThongTinPhuongTien;
            try {
                this.startLoading();
                var form = new FormData();
                form.append("BienKiemSoat", ThongTinPhuongTien.BienKiemSoat);
                form.append("SoKhung", ThongTinPhuongTien.SoKhung || "");
                form.append("SoMay", ThongTinPhuongTien.SoMay || "");
                form.append("SoDangKy", ThongTinPhuongTien.SoDangKyXe || "");
                form.append("IdLoaiXe", ThongTinPhuongTien.LoaiXe);
                form.append("TrongTai", ThongTinPhuongTien.SoCho);
                form.append("SoGhe", ThongTinPhuongTien.SoGhe);
                form.append("SoGiuong", ThongTinPhuongTien.SoGiuong);

                form.append(
                    "IdLoaiSoDo",
                    ThongTinPhuongTien.IdSoDoCho ||
                        "00000000-0000-0000-0000-000000000000"
                );
                form.append(
                    "HanBaoHiem",
                    this.$Helper.getEndDateString(ThongTinPhuongTien.HanBaoHiem)
                );
                form.append(
                    "HanDangKiem",
                    this.$Helper.getEndDateString(
                        ThongTinPhuongTien.HanDangKiem
                    )
                );
                form.append(
                    "HanBienBanTheChap",
                    ThongTinPhuongTien.HanBienNhan
                        ? this.$Helper.getEndDateString(
                              ThongTinPhuongTien.HanBienNhan
                          )
                        : ""
                );
                form.append(
                    "KhoangChuaHang_ChieuDai",
                    ThongTinPhuongTien.ChieuDai || ""
                );
                form.append(
                    "KhoangChuaHang_ChieuRong",
                    ThongTinPhuongTien.ChieuRong || ""
                );
                form.append(
                    "KhoangChuaHang_ChieuCao",
                    ThongTinPhuongTien.ChieuCao || ""
                );
                form.append("IdHangGSHT", ThongTinPhuongTien.HangGSHT || "");
                form.append("GSHT_TaiKhoan", ThongTinPhuongTien.TaiKhoan || "");
                form.append("GSHT_MatKhau", ThongTinPhuongTien.MatKhau || "");
                ThongTinPhuongTien.DanhSachHinhAnh_Push.forEach((e) => {
                    form.append("ThongTinHinhAnhs", e);
                });
                ThongTinPhuongTien.NhaXe.forEach((e) => {
                    form.append("DanhSachIdNhaXe", e);
                });
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.ThemPhuongTien")
                ).Post(form);
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemPhuongTien ~ ex",
                    ex
                );
                this.stopLoading();
            }
        },

        async SuaPhuongTien(store, idPhuongTien) {
            let ThongTinPhuongTien = store.state.ThongTinPhuongTien;
            console.log(
                "🚀 ~ file: ThongTinPhuongTien.js ~ line 282 ~ SuaPhuongTien ~ ThongTinPhuongTien",
                ThongTinPhuongTien
            );
            try {
                this.startLoading();
                var form = new FormData();
                form.append("idXe", idPhuongTien);
                form.append("BienKiemSoat", ThongTinPhuongTien.BienKiemSoat);
                form.append("SoKhung", ThongTinPhuongTien.SoKhung || "");
                form.append("SoMay", ThongTinPhuongTien.SoMay || "");
                form.append("SoDangKy", ThongTinPhuongTien.SoDangKyXe || "");
                form.append("IdLoaiXe", ThongTinPhuongTien.LoaiXe);
                form.append("TrongTai", ThongTinPhuongTien.SoCho);
                form.append("SoGhe", ThongTinPhuongTien.SoGhe);
                form.append("SoGiuong", ThongTinPhuongTien.SoGiuong);

                form.append(
                    "IdLoaiSoDo",
                    ThongTinPhuongTien.IdSoDoCho ||
                        "00000000-0000-0000-0000-000000000000"
                );
                form.append(
                    "HanBaoHiem",
                    this.$Helper.getEndDateString(ThongTinPhuongTien.HanBaoHiem)
                );
                form.append(
                    "HanDangKiem",
                    this.$Helper.getEndDateString(
                        ThongTinPhuongTien.HanDangKiem
                    )
                );
                form.append(
                    "HanBienBanTheChap",
                    ThongTinPhuongTien.HanBienNhan
                        ? this.$Helper.getEndDateString(
                              ThongTinPhuongTien.HanBienNhan
                          )
                        : ""
                );
                form.append(
                    "KhoangChuaHang_ChieuDai",
                    ThongTinPhuongTien.ChieuDai || ""
                );
                form.append(
                    "KhoangChuaHang_ChieuRong",
                    ThongTinPhuongTien.ChieuRong || ""
                );
                form.append(
                    "KhoangChuaHang_ChieuCao",
                    ThongTinPhuongTien.ChieuCao || ""
                );
                form.append("IdHangGSHT", ThongTinPhuongTien.HangGSHT || "");
                form.append("GSHT_TaiKhoan", ThongTinPhuongTien.TaiKhoan || "");
                form.append("GSHT_MatKhau", ThongTinPhuongTien.MatKhau || "");
                ThongTinPhuongTien.DanhSachHinhAnh_Push.forEach((e) => {
                    form.append("ThongTinHinhAnhs", e);
                });
                ThongTinPhuongTien.DanhSachHinhAnh_Details.forEach((e) => {
                    form.append("IdHinhAnhs", e);
                });
                ThongTinPhuongTien.NhaXe.forEach((e) => {
                    form.append("DanhSachIdNhaXe", e);
                });

                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.SuaPhuongTien")
                ).Post(form);
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemPhuongTien ~ ex",
                    ex
                );
                this.stopLoading();
            }
        },

        async XoaPhuongTien(store, idPhuongTien) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.XoaPhuongTien")
                ).Post({
                    idXe: idPhuongTien,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemPhuongTien ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        ResetThongTinPhuongTien(store) {
            ResetThongTinPhuongTien(store);
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
function ResetThongTinPhuongTien(store) {
    let ThongTinPhuongTien = {
        BienKiemSoat: null,
        DVVT: null,
        SoKhung: null,
        SoMay: null,
        LoaiXe: null,
        SoCho: null,
        SoGhe: null,
        SoGiuong: null,
        IdSoDoCho: null,
        SoDangKyXe: null,
        HanBaoHiem: null,
        HanDangKiem: null,
        HanBienNhan: null,
        ChieuDai: null,
        ChieuRong: null,
        ChieuCao: null,
        HangGSHT: null,
        TaiKhoan: null,
        NhaXe: [],
        MatKhau: null,
        DanhSachHinhAnh_Push: [],
        DanhSachHinhAnh_View: [],
        DanhSachHinhAnh_Details: [],
    };
    store.commit("Set", {
        key: "ThongTinPhuongTien",
        data: ThongTinPhuongTien,
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
