<template>
    <div>
        <DxValidationGroup ref="formValidation">
            <span class="font-12">Mã số lệnh</span>
            <DxTextBox
                class="mb-3"
                v-model="data.maLenh"
                validationMessageMode="always"
                mode="text"
                :read-only="true"
                styling-mode="underlined"
            ></DxTextBox>
            <span class="font-12">Lý do (*)</span>
            <DxTextBox
                class="mb-4"
                v-model="model.LyDo"
                validationMessageMode="always"
                mode="text"
                styling-mode="underlined"
            >
                <DxValidator>
                    <DxRequiredRule message="Lý do không được để trống!" />
                    <DxStringLengthRule
                        :max="128"
                        message="Lý do không được vượt quá 128 ký tự!"
                    />
                </DxValidator>
            </DxTextBox>
        </DxValidationGroup>
    </div>
</template>
<script>
import { DxTextBox, DxValidationGroup, DxValidator } from "devextreme-vue";
import { DxRequiredRule, DxStringLengthRule } from "devextreme-vue/data-grid";

export default {
    components: {
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            model: {
                MaLenh: "",
                LyDo: "",
            },
        };
    },
    methods: {},
    created() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>