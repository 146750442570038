export default {
    namespaced: true,
    state: () => ({
        DanhSachHangGPLX: [],
        ThongTinLaiXe: {
            CCCD: null,
            SoGPLX: null,
            HoTen: null,
            HangGPLX: null,
            SoDienThoai: null,
            HanGPLX: null,
            NamHanhNghe: null,
            NgayBatDauHD: new Date(),
            NgayKetThucHD: new Date(
                new Date().setFullYear(new Date().getFullYear() + 1)
            ),
            TinhThanhPho: null,
            QuanHuyen: null,
            DiaChiCuThe: null,
            NhaXe: [],
            DaCoTaiKhoan: false,
            SerialNumber: null,
        },
        SoDienThoaiGoc: null,
        DanhSachNhaXeSelect: [],
        DanhSachNhaXeHoatDongTrenTuyen: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
        DanhSachNhaXeSelect: (state, data) => {
            state.DanhSachNhaXeSelect = data;
        },
        DanhSachNhaXeHoatDongTrenTuyen: (state, data) => {
            state.DanhSachNhaXeHoatDongTrenTuyen = data;
        },
    },

    actions: {
        Reload_DanhSachLaiXe(store, { TextTimKiem, query, key }) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    try {
                        if (TextTimKiem) {
                            loadOptions.filter = [
                                ["HoTen", "contains", TextTimKiem],
                                "or",
                                ["SoGPLX", "contains", TextTimKiem],
                                "or",
                                ["TrangThai", "contains", TextTimKiem],
                                "or",
                                ["SoDienThoai", "contains", TextTimKiem],
                                "or",
                                ["SoCMND", "contains", TextTimKiem],
                            ];
                        }
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t("urlQLTTLenh.DanhSachLaiXe")
                        ).Post({
                            loadOptions: loadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ file: ThongTinLaiXe.js:68 ~ ex:", ex);
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async Get_DanhSachHangGPLX(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachHangGPLX")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    set(store, "DanhSachHangGPLX", result || []);
                } else {
                    set(store, "DanhSachHangGPLX", []);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_ChiTietLaiXe(store, idLaiXe) {
            store;
            try {
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.ChiTietLaiXe"),
                    {
                        idLaiXe: idLaiXe,
                    }
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.status === true) {
                    let ThongTinLaiXe = {
                        CCCD: result.SoCMND,
                        SoGPLX: result.SoGPLX,
                        HoTen: result.HoTen,
                        HangGPLX: result.IdHangGPLXs[0],
                        SoDienThoai: result.SoDienThoai,
                        HanGPLX: new Date(result.HanHieuLuc),
                        NamHanhNghe: result.NamBatDauHanhNghe,
                        NgayBatDauHD: new Date(result.NgayBatDauHopDong),
                        NgayKetThucHD: new Date(result.NgayKetThucHopDong),
                        TinhThanhPho: result.IdTinh,
                        QuanHuyen: result.IdHuyen,
                        DiaChiCuThe: result.DiaChi,
                        NhaXe: result.DanhSachIdNhaXe,
                        DaCoTaiKhoan: result.DaCoTaiKhoan,
                        SerialNumber: result.SerialNumber,
                    };

                    set(store, "ThongTinLaiXe", ThongTinLaiXe);
                    set(store, "SoDienThoaiGoc", result.SoDienThoai);
                    return resp.Data;
                } else {
                    ResetThongTinLaiXe(store);
                    return resp.Data;
                }
            } catch (ex) {
                console.log("🚀 Get_ChiTietLaiXe ~ ex", ex);
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async ThemLaiXe(store) {
            let ThongTinLaiXe = store.state.ThongTinLaiXe;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.ThemLaiXe")
                ).Post({
                    soCMND: ThongTinLaiXe.CCCD,
                    soGPLX: ThongTinLaiXe.SoGPLX,
                    hoTen: ThongTinLaiXe.HoTen,
                    idHangGPLXs: [ThongTinLaiXe.HangGPLX],
                    soDienThoai: ThongTinLaiXe.SoDienThoai,
                    hanHieuLuc: ThongTinLaiXe.HanGPLX,
                    // namBatDauHanhNghe: ThongTinLaiXe.NamHanhNghe || 0,
                    ngayBatDauHopDong: ThongTinLaiXe.NgayBatDauHD,
                    ngayKetThucHopDong: ThongTinLaiXe.NgayKetThucHD,
                    idTinh: ThongTinLaiXe.TinhThanhPho,
                    idHuyen: ThongTinLaiXe.QuanHuyen,
                    diaChi: ThongTinLaiXe.DiaChiCuThe,
                    DanhSachIdNhaXe: ThongTinLaiXe.NhaXe,
                    DaCoTaiKhoan: ThongTinLaiXe.DaCoTaiKhoan,
                    SerialNumber: ThongTinLaiXe.SerialNumber,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLaiXe ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        async SuaLaiXe(store, idLaiXe) {
            let ThongTinLaiXe = store.state.ThongTinLaiXe;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.SuaLaiXe")
                ).Post({
                    idLaiXe: idLaiXe,
                    soCMND: ThongTinLaiXe.CCCD,
                    soGPLX: ThongTinLaiXe.SoGPLX,
                    hoTen: ThongTinLaiXe.HoTen,
                    idHangGPLXs: [ThongTinLaiXe.HangGPLX],
                    soDienThoai: ThongTinLaiXe.SoDienThoai,
                    hanHieuLuc: ThongTinLaiXe.HanGPLX,
                    // namBatDauHanhNghe: ThongTinLaiXe.NamHanhNghe || 0,
                    ngayBatDauHopDong: ThongTinLaiXe.NgayBatDauHD,
                    ngayKetThucHopDong: ThongTinLaiXe.NgayKetThucHD,
                    idTinh: ThongTinLaiXe.TinhThanhPho,
                    idHuyen: ThongTinLaiXe.QuanHuyen,
                    diaChi: ThongTinLaiXe.DiaChiCuThe,
                    DanhSachIdNhaXe: ThongTinLaiXe.NhaXe,
                    DaCoTaiKhoan: ThongTinLaiXe.DaCoTaiKhoan,
                    SerialNumber: ThongTinLaiXe.SerialNumber,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLaiXe ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        async LayDanhSachNhaXe({ commit }) {
            try {
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.LayDanhSachNhaXe")
                ).Get();
                if (resp.Data.errorCode == 0) {
                    commit("DanhSachNhaXeSelect", resp.Data.data);
                }
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLaiXe ~ ex",
                    ex
                );
            }
        },
        async LayDanhSachNhaXeHoatDongTrenTuyen({ commit }, data) {
            try {
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t(
                        "urlQLTTLenh.LayDanhSachNhaXeHoatDongTrenTuyen"
                    ),
                    data
                ).Get();
                if (resp.Data.errorCode == 0) {
                    commit("DanhSachNhaXeHoatDongTrenTuyen", resp.Data.data);
                }
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLaiXe ~ ex",
                    ex
                );
            }
        },

        async XoaLaiXe(store, idLaiXe) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.XoaLaiXe")
                ).Post({
                    idLaiXe: idLaiXe,
                });
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: ThongTin.js ~ line 163 ~ ThemLaiXe ~ ex",
                    ex
                );
                this.stopLoading();
                return false;
            }
        },

        ResetThongTinLaiXe(store) {
            ResetThongTinLaiXe(store);
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
function ResetThongTinLaiXe(store) {
    let ThongTinLaiXe = {
        CCCD: null,
        SoGPLX: null,
        HoTen: null,
        HangGPLX: null,
        SoDienThoai: null,
        HanGPLX: null,
        NamHanhNghe: null,
        NgayBatDauHD: new Date(),
        NgayKetThucHD: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
        ),
        TinhThanhPho: null,
        QuanHuyen: null,
        DiaChiCuThe: null,
        NhaXe: [],
        DaCoTaiKhoan: false,
        SerialNumber: null,
    };
    let array = ["SoDienThoaiGoc"];
    store.commit("Set", {
        key: "ThongTinLaiXe",
        data: ThongTinLaiXe,
    });
    array.forEach((e) => {
        store.commit("Set", {
            key: e,
            data: null,
        });
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
