import BaoCao from "./stores/BaoCao";
import Ve from "./stores/Ve";
import QrCode from "./stores/QrCode";
import HangHoa from "./stores/HangHoa";
import Lenh from "./stores/Lenh";
import ThongTin from "./stores/ThongTin";
import NhanVienBanVe from "./stores/NhanVienBanVe";
import ChuyenDi from "./stores/ChuyenDi";
import DVVTLenh from "./stores/DVVTLenh";
import LenhTuyChon from "./stores/LenhTuyChon";
import TaiKhoan from "./stores/TaiKhoan";
import ThongTinLaiXe from "./stores/QuanLyThongTin/ThongTinLaiXe";
import ThongTinHeThong from "./stores/QuanLyThongTin/ThongTinHeThong";
import TaiKhoanLaiXe from "./stores/QuanLyThongTin/TaiKhoanLaiXe";
import ThongTinLuongTuyen from "./stores/QuanLyThongTin/ThongTinLuongTuyen";
import ThongTinBenXe from "./stores/QuanLyThongTin/ThongTinBenXe";
import ThongTinPhuongTien from "./stores/QuanLyThongTin/ThongTinPhuongTien";
import ThongBao from "./stores/ThongBao";
import TinhToanNgayChay from "./stores/TinhToanNgayChay";
export default {
    modules: {
        BaoCao,
        Ve,
        QrCode,
        HangHoa,
        Lenh,
        ThongTin,
        NhanVienBanVe,
        ChuyenDi,
        DVVTLenh,
        LenhTuyChon,
        TaiKhoan,
        ThongTinLaiXe,
        ThongTinHeThong,
        TaiKhoanLaiXe,
        ThongTinLuongTuyen,
        ThongTinBenXe,
        ThongTinPhuongTien,
        ThongBao,
        TinhToanNgayChay,
    },
};
