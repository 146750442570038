export default {
    namespaced: true,
    state: () => ({
        DanhSachTinhThanh: [],
        DanhSachQuanHuyen: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async Get_DanhSachTinhThanh(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachTinhThanh")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    store.state.DanhSachTinhThanh = result || [];
                    let item = store.state.DanhSachTinhThanh.find(
                        (e) =>
                            e.TenTinh ==
                            this.$i18n.global.t("string.KhongXacDinh")
                    );
                    let index = store.state.DanhSachTinhThanh.indexOf(item);
                    if (index !== -1) {
                        store.state.DanhSachTinhThanh.splice(index, 1);
                        store.state.DanhSachTinhThanh.unshift(item);
                    }
                } else {
                    store.state.DanhSachTinhThanh = [];
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async Get_DanhSachQuanHuyen(store, idTinh) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachQuanHuyen"),
                    {
                        idTinh: idTinh,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    store.state.DanhSachQuanHuyen = result || [];
                } else {
                    store.state.DanhSachQuanHuyen = [];
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
    },
};
