<template>
    <ion-page data-pageid="tab1">
        <ion-content>
            <TrangChuVue>
                <template #LenhDienTu>
                    <div>
                        <TrangChuLenhDangThucHienVue />
                    </div>
                </template>
            </TrangChuVue>
        </ion-content>
    </ion-page>
</template>

<script>
import TrangChuVue from "../components/_Common/TrangChu";
import TrangChuLenhDangThucHienVue from "../components/TrangChuLenhDangThucHien";
import TaiKhoan from "../../class/TaiKhoan";
import {
    IonFooter,
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
} from "@ionic/vue";
export default {
    components: {
        TrangChuVue,
        TrangChuLenhDangThucHienVue,
        IonFooter,
        IonPage,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
    },
    methods: {
        dieuHuong(item) {
            this.$router.push(item.link);
        },
    },
    async mounted() {
        //Lấy thông tin cấu hình chung khi cache chưa có
        let check = false;
        let rs = await new TaiKhoan().layCauHinhChung();
        let CauHinhChung = new TaiKhoan().cauHinhChung();
        if (CauHinhChung) {
            for (const key in CauHinhChung) {
                if (Object.hasOwnProperty.call(CauHinhChung, key)) {
                    const value = CauHinhChung[key];
                    if (value != rs[key]) {
                        check = true;
                        break;
                    }
                }
            }
        } else {
            //Lưu vào cache
            localStorage.setItem(
                "ThongTinCauHinhChung",
                JSON.stringify(rs.Data.data)
            );
        }
        if (check) {
            //Lưu vào cache
            localStorage.setItem(
                "ThongTinCauHinhChung",
                JSON.stringify(rs.Data.data)
            );
        }
    },
};
</script>
<style lang="scss" scoped>
</style>

