<template>
    <div class="row justify-center align-center" style="height: 100%">
        <img
            src="../../../assets/images/image-icon-tadah.gif"
            style="width: 214px; height: 244px; object-fit: cover"
        />

        <canvas id="the-canvas" hidden></canvas>
    </div>
</template>

<script>
export default {
    methods: {
        async downloadImg() {
            let self = this;
            let url = self.$route.query.link;
            var pages = [],
                heights = [],
                width = 0,
                height = 0,
                currentPage = 1;
            var scale = 1.5;

            function draw() {
                var canvas = document.createElement("canvas"),
                    ctx = canvas.getContext("2d");
                canvas.style.display = "none";
                canvas.width = width;
                canvas.height = height;
                for (var i = 0; i < pages.length; i++)
                    ctx.putImageData(pages[i], 0, heights[i]);
                document.body.appendChild(canvas);
                var img = new Image();
                img.src = canvas.toDataURL();
                function dataURLtoFile(dataurl, filename) {
                    var arr = dataurl.split(","),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]),
                        n = bstr.length,
                        u8arr = new Uint8Array(n);

                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }

                    return new File([u8arr], filename, { type: mime });
                }

                //Usage example:
                var file = dataURLtoFile(img.src, "hello.img");
                let url = URL.createObjectURL(file);
                let day = `${new Date().getDay() + 1}`;
                let month = `${new Date().getMonth() + 1}`;
                let year = `${new Date().getFullYear()}`;
                console.log(url);
                fetch(url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        saveAs(blob, `${year}-${month}-${day}-BanTheHienLenh`);
                    });
                window.location = url;
            }
            import("../../../utils/pdfjs-2.9.359-dist/build/pdf").then(
                (pdfjsLib) => {
                    pdfjsLib.GlobalWorkerOptions.workerSrc =
                        "/mylibs/pdf/pdf.worker.js";

                    var loadingTask = pdfjsLib.getDocument(url);
                    loadingTask.promise.then(function (pdf) {
                        getPage();
                        function getPage() {
                            pdf.getPage(currentPage).then(function (page) {
                                console.log("Printing:" + currentPage);
                                var viewport = page.getViewport({ scale });
                                var canvas = document.createElement("canvas"),
                                    ctx = canvas.getContext("2d");
                                var renderContext = {
                                    canvasContext: ctx,
                                    viewport: viewport,
                                };

                                canvas.height = viewport.height;
                                canvas.width = viewport.width;

                                const mypage = page.render(renderContext);
                                mypage.promise.then(function () {
                                    pages.push(
                                        ctx.getImageData(
                                            0,
                                            0,
                                            canvas.width,
                                            canvas.height
                                        )
                                    );

                                    heights.push(height);
                                    height += canvas.height;
                                    if (width < canvas.width)
                                        width = canvas.width;

                                    if (currentPage < pdf.numPages) {
                                        currentPage++;
                                        getPage();
                                    } else {
                                        draw();
                                    }
                                });
                            });
                        }
                    });
                }
            );
        },
    },
    created() {
        if (this.$route.query.link) {
            this.downloadImg();
        }
    },
};
</script>

<style scoped>
</style>