export default {
    namespaced: true,
    state: () => ({
        DiemDen: null,
        DiemXuatPhat: null,
        NgayTimKiem: new Date(),
        TextTimKiem: null,
        TextTimKiemDiemXuatPhat: null,
        ProcessGetDiemDen: false,
        ProcessGetDiemXuatPhat: false,
        DanhSachTieuChi: [
            {
                id: 1,
                text: "Bến đến",
                order: 0,
                truongSapXep: "loTrinh.diemDung.id",
                kieuSapXep: "",
            },
            {
                id: 2,
                text: "Giờ XB",
                order: 0,
                truongSapXep: "gioXuatBenDuKien",
                kieuSapXep: "",
            },
            {
                id: 3,
                text: "BKS",
                order: 0,
                truongSapXep: "xe_BienSoXe",
                kieuSapXep: "",
            },
            {
                id: 4,
                text: "Giá vé",
                order: 0,
                truongSapXep: "giaVeMin",
                kieuSapXep: "",
            },
        ],
        BanVeThanhCong: false,
        VeTrangChu: false,
    }),
    getters: {},
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        LayDanhSachDiemDen(store, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        set(store, "ProcessGetDiemDen", false);
                        let listFieldSearch = [
                            "tenViTriChoTimKiem",
                            "diaChiTimKiem",
                        ];
                        let customLoadOptions = { ...loadOptions };
                        if (customLoadOptions.searchValue) {
                            set(
                                store,
                                "TextTimKiem",
                                customLoadOptions.searchValue
                            );
                        }
                        let filter = [];
                        customLoadOptions.filter = undefined;
                        if (store.state.TextTimKiem) {
                            filter = listFieldSearch
                                .map((e) => [
                                    e,
                                    "contains",
                                    store.state.TextTimKiem,
                                ])
                                .reduce(
                                    (filter, x, i, a) => (
                                        filter.push(x),
                                        i < a.length - 1
                                            ? filter.push("or")
                                            : !0,
                                        filter
                                    ),
                                    []
                                );
                            customLoadOptions.filter = filter || null;
                        }
                        customLoadOptions.searchOperation = undefined;
                        customLoadOptions.searchValue = undefined;
                        customLoadOptions.userData = undefined;
                        customLoadOptions.searchExpr = undefined;
                        customLoadOptions.take = 10;
                        let resp = await this.$Core.Api.QuanLyThongTinVe(
                            this.$i18n.global.t("url.LayDanhSachDiemDen")
                        ).Post({
                            args: {},
                            loadOptions: JSON.stringify(customLoadOptions),
                        });

                        if (resp.StatusCode === 200) {
                            let data = resp.Data.data.data
                                ? resp.Data.data
                                : [];
                            set(store, "ProcessGetDiemDen", true);

                            return data;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },
        DanhSachDiemXuatPhat(store, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        set(store, "ProcessGetDiemXuatPhat", false);
                        let listFieldSearch = [
                            "tenViTriChoTimKiem",
                            "diaChiTimKiem",
                        ];
                        let customLoadOptions = { ...loadOptions };
                        if (customLoadOptions.searchValue) {
                            set(
                                store,
                                "TextTimKiemDiemXuatPhat",
                                customLoadOptions.searchValue
                            );
                        }
                        let filter = [];
                        customLoadOptions.filter = undefined;
                        if (store.state.TextTimKiemDiemXuatPhat) {
                            filter = listFieldSearch
                                .map((e) => [
                                    e,
                                    "contains",
                                    store.state.TextTimKiemDiemXuatPhat,
                                ])
                                .reduce(
                                    (filter, x, i, a) => (
                                        filter.push(x),
                                        i < a.length - 1
                                            ? filter.push("or")
                                            : !0,
                                        filter
                                    ),
                                    []
                                );
                            customLoadOptions.filter = filter || null;
                        }
                        customLoadOptions.searchOperation = undefined;
                        customLoadOptions.searchValue = undefined;
                        customLoadOptions.userData = undefined;
                        customLoadOptions.searchExpr = undefined;
                        customLoadOptions.take = 10;
                        let resp = await this.$Core.Api.QuanLyThongTinVe(
                            this.$i18n.global.t("url.LayDanhSachDiemDen")
                        ).Post({
                            args: {},
                            loadOptions: JSON.stringify(customLoadOptions),
                        });

                        if (resp.StatusCode === 200) {
                            let data = resp.Data.data.data
                                ? resp.Data.data
                                : [];
                            set(store, "ProcessGetDiemXuatPhat", true);

                            return data;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        LayDanhSachChuyenDi(store, query, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let DiemDen = store.state.DiemDen;
                        let DiemXuatPhat = store.state.DiemXuatPhat;
                        let NgayTimKiem = store.state.NgayTimKiem;
                        let DanhSachTieuChi = store.state.DanhSachTieuChi;
                        let query = {
                            sortOptions: DanhSachTieuChi || [],
                            // thoiDiemBatDau: new Date(NgayTimKiem)
                            //     .toISOString()
                            //     .substring(0, 10),
                            tuNgay: this.$Helper.getStartDay(NgayTimKiem),
                            denNgay: this.$Helper.getEndDay(NgayTimKiem),
                            maDiemDi: DiemXuatPhat
                                ? DiemXuatPhat.placemarkId
                                : null,
                            maDiemDen: DiemDen
                                ? DiemDen.placemarkId
                                : "00000000-0000-0000-0000-000000000000",
                            loaiDiemDen: DiemDen
                                ? DiemDen.loaiViTri
                                : "Province",
                            thongTinViTri: {
                                longitude: 0,
                                latitude: 0,
                            },
                        };
                        let resp = await this.$Core.Api.DiemXuongCuaKhachHang(
                            this.$i18n.global.t(
                                "url.DanhSachChuyenDiDuocUyThac"
                            )
                        ).Post({
                            args: query || {},
                            loadOptions: loadOptions,
                        });
                        this.stopLoading();
                        // DanhSachChuyenDiDuocUyThac
                        // LayDanhSachChuyenDi
                        if (resp.StatusCode === 200) {
                            let data = resp.Data.data.data
                                ? resp.Data.data
                                : [];

                            return data;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        LayDanhSachKhachNgoiGhePhu(store, guidChuyenDi, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.DonHang(
                            this.$i18n.global.t("url.DanhSachMuaVeGhePhu")
                        ).Post({
                            args: guidChuyenDi,
                            loadOptions: loadOptions,
                        });
                        this.stopLoading();
                        if (resp.StatusCode === 200) {
                            let data = resp.Data.data.data
                                ? resp.Data.data
                                : [];
                            return data;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },
        LayDanhSachKhachBanVeKhongSoDo(store, guidChuyenDi, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        this.startLoading();
                        let resp = await this.$Core.Api.DonHang(
                            this.$i18n.global.t("url.KhongCoSoDoCho_LayDanhSachKhachMuaVe")
                        ).Post({
                            args: guidChuyenDi,
                            loadOptions: loadOptions,
                        });
                        this.stopLoading();
                        if (resp.StatusCode === 200) {
                            let data = resp.Data.data.data
                                ? resp.Data.data
                                : [];
                            return data;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async KiemTraTrangThaiChuyenDi(store, id) {
            try {
                let resp = await this.$Core.Api.ChuyenDi(
                    this.$i18n.global.t("url.KiemTraTrangThaiChuyenDi"),
                    {
                        maChuyenDi: id,
                    }
                ).Get();
                return resp.Data;
            } catch (error) {
                console.log("🚀 ~  error", error);
            }
        },
        async ThucHienBanVeUyThacTienMat(store, query) {
            try {
                let resp = await this.$Core.Api.DonHang(
                    this.$i18n.global.t("url.ThucHienBanVeUyThacTienMat")
                ).Post(query);
                return resp.Data;
            } catch (error) {
                console.log("🚀 ~  error", error);
            }
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
