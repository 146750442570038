export default {
    namespaced: true,
    state: () => ({
        ToaDo: "",
        DanhSachHangGPLX: [],
        DanhSachLaiXeTaoLenhTuyChon: [],
    }),
    getters: {},
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async LayThongTinChuyenDiTheoLenh(state, idLenhDienTu) {
            try {
                this.startLoading();
                let rs = await $Core.Api.ThongTin(
                    this.$i18n.global.t("urlQLTTVe.LayThongTinChuyenDiTheoLenh"),
                    { idLenhDienTu: idLenhDienTu }
                ).Get();
                this.stopLoading();
                return rs;
            } catch (error) {
                console.error(error);
                this.stopLoading();
            }
        },
        async LayThongTinPhienBan(state, query) {
            try {
                let rs = await $Core.Api.PhienBan(
                    this.$i18n.global.t("urlQLPB.KiemTraCapNhat"),
                    query
                ).Get();
                return rs;
            } catch (error) {
                console.error(error);
            }
        },
        async LayThongTinViTri(store) {
            setInterval(() => {
                new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        ({ coords }) => {
                            let ToaDo = {
                                latitude: coords.latitude,
                                longitude: coords.longitude,
                            };
                            store.commit("Set", {
                                key: "ToaDo",
                                data: ToaDo,
                            });
                            resolve(ToaDo);
                        },
                        (err) => {
                            store.commit("Set", {
                                key: "ToaDo",
                                data: "",
                            });
                            // console.log("🚀 ~  err", err);
                            resolve("null");
                        },
                        { timeout: 3000, enableHighAccuracy: true }
                    );
                });
            }, 5000);
        },

        async Get_DanhSachHangGPLX(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachHangGPLX")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    store.state.DanhSachHangGPLX = result;
                } else {
                    store.state.DanhSachHangGPLX = [];
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachLaiXeTaoLenhTuyChon(store, key, query) {
            let resp = await this.$Core.Api.QuanLyThongTinLenh(
                this.$i18n.global.t("urlQLTTLenh.DanhSachLaiXeTaoLenhTuyChon")
            ).Post({ skip: 0, take: 0 });
            if (resp.Data.status) {
                let result = resp.Data.data.data ?? [];
                store.state.DanhSachLaiXeTaoLenhTuyChon = result;
                return { Store: result };
            } else {
                return { Store: [] };
            }
        },

        async Get_DanhSachPhuongTienTaoLenhTuyChon(store, { query }, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachPhuongTienTaoLenhTuyChon"
                            )
                        ).Post({
                            loadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            // set(
                            //     store,
                            //     "KyLenhTuyChon_DanhSachPhuongTien",
                            //     result.data || []
                            // );
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    }
                },
                key || null,
                async (key) => {
                    try {
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachPhuongTienTaoLenhTuyChon"
                            )
                        ).Post({
                            loadOptions: {
                                filter: ["ID_DnvtXe", "=", key],
                            },
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let data =
                                resp.Data.data?.data.length > 0
                                    ? resp.Data.data.data[0]
                                    : {};
                            return data;
                        } else {
                            return {};
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                }
            );
            return load;
        },
        async CapNhatThongTinGiayToXe({ commit }, data) {
            try {
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.CapNhatThongTinGiayToXe")
                ).Post(data);
                return resp.Data;
            } catch (ex) {
                console.log("🚀 ~ ex", ex);
            }
        },
    },
};
