export default function App($Core) {
    this.InVe = async (url, type, ma, layFilePdf = true) => {
        if (!ma) {
            return "error";
        }
        window.$loading.show();
        let banTheHienVe = await $Core.Api.Print(url, {
            [type]: ma,
            layFilePdf: layFilePdf
        })
            .Config(c => {
                c.responseType = "blob";
                c.validateStatus = () => true;
            })
            .Get();
        window.$loading.hide();

        if (banTheHienVe.StatusCode == 200) {
            async function blob2arrayBuffer(blob) {
                return new Promise(function(ok) {
                    let fr = new FileReader();
                    fr.onloadend = function(process) {
                        ok(fr.result);
                    };
                    fr.onerror = function() {
                        ok(null);
                    };
                    fr.onerror = function() {
                        ok(null);
                    };
                    fr.readAsArrayBuffer(blob);
                });
            }
            // var banTheHienVe_data = await banTheHienVe.Data.arrayBuffer();
            var banTheHienVe_data = await blob2arrayBuffer(banTheHienVe.Data);

            var printData = Array.from(new Uint8Array(banTheHienVe_data))
                .map(x => x.toString(16))
                .map(x => (x.length == 1 ? "0" + x : x))
                .join("");

            if (
                window.hasOwnProperty("SonPhat") &&
                SonPhat.canPrintOverRawBT()
            ) {
                SonPhat.printPdfRawBT(printData);
            } else if (!!PrinterNative && !!PrinterNative.PrintPdf) {
                var ret = PrinterNative.PrintPdf(banTheHienVe_data);
                console.log(ret);
            } else {
                if (
                    window.hasOwnProperty("SonPhat") &&
                    SonPhat.canPrintOverSunmi()
                ) {
                    SonPhat.printPdfSunmi(printData);
                } else if (window.hasOwnProperty("SonPhat")) {
                    SonPhat.printPdf(printData);
                } else {
                }
            }
        } else {
            return "error";
        }
        return "success";
    };

    this.InVeTheoThongTinMoRong = async (url, query, layFilePdf = true) => {
        if (!query) {
            return "error";
        }
        window.$loading.show();
        let banTheHienVe = await $Core.Api.Print(url, {
            ...query,
            layFilePdf: layFilePdf
        })
            .Config(c => {
                c.responseType = "blob";
                c.validateStatus = () => true;
            })
            .Get();
        window.$loading.hide();

        if (banTheHienVe.StatusCode == 200) {
            async function blob2arrayBuffer(blob) {
                return new Promise(function(ok) {
                    let fr = new FileReader();
                    fr.onloadend = function(process) {
                        ok(fr.result);
                    };
                    fr.onerror = function() {
                        ok(null);
                    };
                    fr.onerror = function() {
                        ok(null);
                    };
                    fr.readAsArrayBuffer(blob);
                });
            }
            // var banTheHienVe_data = await banTheHienVe.Data.arrayBuffer();
            var banTheHienVe_data = await blob2arrayBuffer(banTheHienVe.Data);

            var printData = Array.from(new Uint8Array(banTheHienVe_data))
                .map(x => x.toString(16))
                .map(x => (x.length == 1 ? "0" + x : x))
                .join("");

            if (
                window.hasOwnProperty("SonPhat") &&
                SonPhat.canPrintOverRawBT()
            ) {
                SonPhat.printPdfRawBT(printData);
            } else if (!!PrinterNative && !!PrinterNative.PrintPdf) {
                var ret = PrinterNative.PrintPdf(banTheHienVe_data);
                console.log(ret);
            } else {
                if (
                    window.hasOwnProperty("SonPhat") &&
                    SonPhat.canPrintOverSunmi()
                ) {
                    SonPhat.printPdfSunmi(printData);
                } else if (window.hasOwnProperty("SonPhat")) {
                    SonPhat.printPdf(printData);
                } else {
                }
            }
        } else {
            return "error";
        }
        return "success";
    };
}
