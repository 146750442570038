export default {
    namespaced: true,
    state: () => ({
        viewPage: "ChuyenDiKeHoachChiTiet",
        ChiTietKeHoach: {
            TenTuyen: null,
            TenBenXeDi: null,
            Not: "00:00:00",
            IdNhaXe: null,
        },
        maxDate: new Date("2999-12-31T16:59:59.000Z"),
        minDate: new Date("1970-01-01T00:00:00.000Z"),
        danhSachLaiXe: [],
        laiXeTiepNhanLenh: {},
        bienKiemSoat: {},
        hoTenPhuXe: "",
        dialogHuyLenh: false,
        idLenhDienTu: "",
        DanhSachTieuChi: [
            {
                text: "Giờ XB",
                value: "GioXuatBen",
                order: 0,
            },
            {
                text: "BKS",
                value: "BienSoXe",
                order: 0,
            },
            {
                text: "Tuyến vận chuyển",
                value: "TenTuyen",
                order: 0,
            },
        ],
        GuidLenh: "",
        dialogTheHien: false,
        IdChuyenDiBanVeDangChon: "",
        dialogMatMaBaoMat: false,
        dialogKyLaiLenh: false,
        dialogDungHanhTrinh: false,
        itemLuaChonDungHanhTrinh: {
            MaSoLenh: "x",
            TenTrangThaiLenh: "x",
            GioXuatBen: "x",
            BienSoXe: "x",
            TenTuyen: "x",
            MaTuyen: "x",
        },
        NgayDuong: new Date(),
        TenTuyen: null,
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh: {},
        ThongTinChinhSuaLenh: {},
        DanhSachCauHinhSuDung: [],
        DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh: [],
        DanhSachCauHinhDuocPhepChinhSuaLenh: [],
        DanhSachLenhCuaLaiXeSauKhiKyLenh: [],
        ThongTinKyLenh: {
            QueryRouter: {},
            ThongTinChung: {},
            LuongChonBienKiemSoatTruoc: false,
            LuongChonDoiXeTruoc: false,
            DanhSachGioXBKeHoach: [],
            DanhSachXe: [],
            DanhSachDoiXeDangKyTrenTuyen: [],
            DanhSachNhaXe: [],
            DanhSachIdLaiXeGoiY: [],
            DanhSachTatCaLaiXe: [],
            DanhSachNgayKeHoach: [],
            DanhSachNgayDuocChonKyLenh: [],
        },
        ThongTinSuaChuyenDiKeHoach: {
            DanhSachLaiXeThucHien: [null, null, null],
        },
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
        SET_VIEWPAGE(state, data) {
            state.viewPage, data;
        },
        SET_CHI_TIET_KE_HOACH(state, data) {
            state.ChiTietKeHoach = data;
        },
        SET_DANH_SACH_LAI_XE(state, data) {
            state.danhSachLaiXe = data;
        },
        SET_LAI_XE_TIEP_NHAN_LENH(state, data) {
            state.laiXeTiepNhanLenh = data;
        },
        SET_BIEN_KIEM_SOAT(state, data) {
            state.bienKiemSoat = data;
        },
        SET_HO_TEN_PHU_XE(state, data) {
            state.hoTenPhuXe = data;
        },
        SET_DIALOG_LENH(state, data) {
            state.dialogHuyLenh = data;
        },
        SET_IDLENH(state, data) {
            state.idLenhDienTu = data;
        },
        SET_GUID_LENH(state, data) {
            state.GuidLenh = data;
        },
        SET_DIALOG_THEHIEN(state, data) {
            state.dialogTheHien = data;
        },
        SET_ID_CHUYEN_DI_BAN_VE_DANG_CHON(state, data) {
            state.IdChuyenDiBanVeDangChon = data;
        },
        SET_DIALOG_BAO_MAT(state, data) {
            state.dialogMatMaBaoMat = data;
        },
        SET_DIALOG_KY_LAI_LENH(state, data) {
            state.dialogKyLaiLenh = data;
        },
        Reset_ThongTinKyLenh(state) {
            state.ThongTinKyLenh = {
                QueryRouter: {},
                ThongTinChung: {},
                LuongChonBienKiemSoatTruoc: false,
                LuongChonDoiXeTruoc: false,
                DanhSachGioXBKeHoach: [],
                DanhSachXe: [],
                DanhSachDoiXeDangKyTrenTuyen: [],
                DanhSachNhaXe: [],
                DanhSachIdLaiXeGoiY: [],
                DanhSachTatCaLaiXe: [],
                DanhSachNgayKeHoach: [],
                DanhSachNgayDuocChonKyLenh: [],
            };
        },
        Reset_ThongTinSuaChuyenDiKeHoach(state) {
            state.ThongTinSuaChuyenDiKeHoach = {
                DanhSachLaiXeThucHien: [null, null, null],
            };
        },
    },

    getters: {
        SoLuongCauHinhToiDa(state) {
            let total;
            state.DanhSachCauHinhSuDung.forEach((e) => {
                if (e.MaCauHinh == "SO_LUONG_CHUYEN_DI_TOI_DA") {
                    total = parseInt(e.GiaTri);
                }
            });
            return total;
        },
        SoLuongLenhDuocHuyToiDa(state) {
            let total;
            state.DanhSachCauHinhSuDung.forEach((e) => {
                if (e.MaCauHinh == "SO_LUONG_LENH_HUY_TOI_DA") {
                    total = parseInt(e.GiaTri);
                }
            });
            return total;
        },
        SoLuongNgayKyLenhToiDa(state) {
            let total = 31;
            state.DanhSachCauHinhSuDung.forEach((e) => {
                if (e.MaCauHinh == "SO_LUONG_NGAY_KY_LENH_TOI_DA") {
                    total = parseInt(e.GiaTri);
                }
            });
            return total;
        },
        SoLuongLenhKyLenhToiDa(state) {
            let total = 62;
            state.DanhSachCauHinhSuDung.forEach((e) => {
                if (e.MaCauHinh == "SO_LUONG_LENH_KY_LENH_TOI_DA") {
                    total = parseInt(e.GiaTri);
                }
            });
            return total;
        },
    },
    actions: {
        Reload_DanhSachChuyenDiDuKien(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;

            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "GioXuatBen") {
                            itemTieuChi.value = "Not";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }

                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachChuyenDiDuKien"
                            )
                        ).Post({
                            custom: query,
                            loadOptions: customLoadOptions,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachChuyenDiDuKienV1(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;

            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = JSON.parse(
                        JSON.stringify(
                            store.state.DanhSachTieuChi.find(
                                (e) => e.order != 0
                            ) || null
                        )
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "TenTuyen") {
                            itemTieuChi.value = "TenBenXeDi";
                        }
                        if (itemTieuChi.value == "BienSoXe") {
                            itemTieuChi.value = "BienKiemSoat";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }

                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachKeHoachVersion1"
                            )
                        ).Post({
                            custom: query,
                            loadOptions: customLoadOptions,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex:", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachChuyenDiDuKienV2(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;

            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = JSON.parse(
                        JSON.stringify(
                            store.state.DanhSachTieuChi.find(
                                (e) => e.order != 0
                            ) || null
                        )
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "GioXuatBen") {
                            itemTieuChi.value = null;
                        }
                        if (itemTieuChi.value == "TenTuyen") {
                            itemTieuChi.value = "TenTuyen";
                        }
                        if (itemTieuChi.value == "BienSoXe") {
                            itemTieuChi.value = null;
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }

                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachKeHoachVersion2"
                            )
                        ).Post({
                            custom: query,
                            loadOptions: customLoadOptions,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex:", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhDaCap(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "Not") {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }

                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhDaCap"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhDangThucHien(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "Not") {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhDangThucHien"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhDaHoanThanh(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "Not") {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhDaHoanThanh"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },
        Reload_DanhSachLenhKhongHoanThanh(
            store,
            { textTimKiem, listFieldSearch, query, key }
        ) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    this.startLoading();
                    let customLoadOptions = { ...loadOptions };
                    let itemTieuChi = store.state.DanhSachTieuChi.find(
                        (e) => e.order != 0
                    );
                    if (itemTieuChi != undefined) {
                        if (itemTieuChi.value == "Not") {
                            itemTieuChi.value = "GioXuatBen";
                        }
                    }
                    let filter = [];
                    if (textTimKiem) {
                        // listFieldSearch.forEach((e, index) => {
                        //     let params = null;
                        //     if (index < listFieldSearch.length - 1) {
                        //         params = [[e, "contains", textTimKiem], "or"];
                        //     } else if (index == listFieldSearch.length - 1) {
                        //         params = [e, "contains", textTimKiem];
                        //     }
                        //     filter = filter.concat(params);
                        // });
                        filter = listFieldSearch
                            .map((e) => [e, "contains", textTimKiem])
                            .reduce(
                                (filter, x, i, a) => (
                                    filter.push(x),
                                    i < a.length - 1 ? filter.push("or") : !0,
                                    filter
                                ),
                                []
                            );
                        customLoadOptions.filter = filter || null;
                    }
                    if (itemTieuChi) {
                        customLoadOptions.sort = [
                            {
                                desc: itemTieuChi.kieuSapXep == "DESC",
                                selector: itemTieuChi.value,
                            },
                        ];
                    }
                    try {
                        let resp = await this.$Core.Api.QuanLyLenh(
                            this.$i18n.global.t(
                                "urlDNQuanLyLenh.DanhSachLenhKhongHoanThanh"
                            )
                        ).Post({
                            loadOptions: customLoadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    } finally {
                        this.stopLoading();
                    }
                },
                key || null
            );
            return load;
        },

        async Get_DanhSachLuongTuyen(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachLuongTuyenCapTuyen"
                    )
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachXeDuKienTheoKeHoach(store, payload) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachXeDuKienTheoKeHoach"
                    ),
                    {
                        IdKeHoach: payload.IdKeHoach,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async Get_DanhSachLaiXeDuKienTheoKeHoach(store, payload) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachLaiXeDuKienTheoKeHoach"
                    ),
                    {
                        IdKeHoach: payload.IdKeHoach,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                store.commit("SET_DANH_SACH_LAI_XE", result);
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async ChiTietKeHoach(store, { query }) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.ChiTietKeHoach"),
                    {
                        TuNgay: this.$Helper.getStartDay(query.TuNgay),
                        DenNgay: this.$Helper.getEndDay(query.DenNgay),
                        idKeHoach: query.IdKeHoach,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (query.changeKeHoach) {
                    store.commit("SET_CHI_TIET_KE_HOACH", result[0]);
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async CapNhatKeHoach(store, query) {
            store;

            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.CapNhatKeHoach")
                ).Post(query);
                this.stopLoading();
                // let result = resp.Data.data;
                return resp.Data;
            } catch (ex) {
                this.stopLoading();
                return false;
            }
        },
        async TaoVaKyLenh(store, payload) {
            store;
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.TaoVaKyLenh")
                ).Post(payload);
                return resp;
            } catch (err) {
                console.log(err);
            }
        },
        async HuyLenh(store, payload) {
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.HuyLenh")
                ).Post({ IdLenh: payload.IdLenhDienTu });
                this.stopLoading();
                return resp;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        async Get_ChiTietLenh(store, { IdLenhDienTu }) {
            this.startLoading();
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.ChiTietLenh"),
                    { IdLenhDienTu: IdLenhDienTu }
                ).Get();
                this.stopLoading();
                return resp;
            } catch (err) {
                console.log(err);
            }
        },
        async TaoChuyenDiBanVeTheoLenh(store, data) {
            try {
                let rs = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.TaoChuyenDiBanVeTheoLenh"
                    )
                ).Post(data);

                return rs.Data;
            } catch (err) {
                console.log(err);
            }
        },
        async KyLaiLenh(store, { IdLenhDienTu }) {
            try {
                let resp = await this.$Core.Api.KySoDienTu(
                    this.$i18n.global.t("urlDNQuanLyLenh.KyLaiLenh")
                ).Post({
                    idLenhDienTus: IdLenhDienTu,
                    idTaiKhoan: "00000000-0000-0000-0000-000000000000",
                    hoTen: "",
                });
                return resp;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        async DungHanhTrinh(store, { DataPost }) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.DungHanhTrinh")
                ).Post(DataPost);
                this.stopLoading();
                return resp;
            } catch (err) {
                console.log(err);
            }
        },
        async LayChiTietCauHinhDoanhNghiepTheoMaCauHinh(
            store,
            { DanhSachMaCauHinhCanLay, IdDoanhNghiep }
        ) {
            try {
                let rs = await this.$Events.RequestPost({
                    keyRequest: "LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    ServicesBE: "QuanLyThongTinLenh",
                    url: this.$i18n.global.t(
                        "urlDNQuanLyLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh"
                    ),
                    dataPost: {
                        maCauHinhs: DanhSachMaCauHinhCanLay,
                        IdDnvt: IdDoanhNghiep || null,
                    },
                    msgSuccess: null,
                    msgError: "Lấy cấu hình doanh nghiệp thất bại!",
                    msgCatch: "Lấy cấu hình doanh nghiệp thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                if (rs.status) {
                    let data = rs.data || [];
                    DanhSachMaCauHinhCanLay.forEach((e) => {
                        let item = data.find((_data) => _data.MaCauHinh == e);
                        store.state.ChiTietCauHinhDoanhNghiepTheoMaCauHinh[e] =
                            item?.GiaTri || null;
                    });
                }
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async LayCauHinhChinhSuaKhiKyLenh(
            store,
            { IdNhaXe, IdDnvtTuyen, IdDnvtXe }
        ) {
            try {
                let dataGet = {
                    IdNhaXe: IdNhaXe || "00000000-0000-0000-0000-000000000000",
                    IdDnvtTuyen:
                        IdDnvtTuyen || "00000000-0000-0000-0000-000000000000",
                    IdDnvtXe:
                        IdDnvtXe || "00000000-0000-0000-0000-000000000000",
                };
                let rs = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.LayThongTinCauHinhDuocPhepChinhSuaKhiKyLenh"
                    ),
                    dataGet
                ).Get();

                return rs.Data;
            } catch (error) {
                console.log("🚀 ~ file: Lenh.js", error);
            }
        },
        async LayPhamViDuLieuDuocPhepChinhSuaKhiSuaLenh(
            store,
            { idLenhDienTu }
        ) {
            try {
                let dataGet = {
                    idLenhDienTu: idLenhDienTu,
                };
                let rs = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.LayPhamViDuLieuDuocPhepChinhSuaKhiSuaLenh"
                    ),
                    dataGet
                ).Get();
                if (rs.Data.status) {
                    store.state.DanhSachCauHinhDuocPhepChinhSuaLenh =
                        rs.Data.data;
                }

                return rs.Data;
            } catch (error) {}
        },
        async CapNhatThongTinGiayToLaiXe({ commit }, data) {
            try {
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t(
                        "urlQLTTLenh.CapNhatThongTinGiayToLaiXe"
                    )
                ).Post(data);
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: DVVTLenh.js:720 ~ CapNhatThongTinGiayToLaiXe ~ ex:",
                    ex
                );
            }
        },
        async LayLenhCuaLaiXeSauKhiKyLenh(store, { query }) {
            store;
            this.startLoading();
            let loadOptions = {
                searchOperation: "contains",
                searchValue: null,
                skip: 0,
                take: 0,
                userData: {},
                filter: ["idTrangThai", "=", 1],
            };
            try {
                let resp = await this.$Core.Api.LenhMobile(
                    this.$i18n.global.t("url.LayDSTatCaLenh")
                ).Post({
                    loadOptions: loadOptions || {},
                    custom: query,
                });
                if (resp.Data.status === true) {
                    let result = resp.Data.data?.data || [];
                    return result;
                } else {
                    return [];
                }
            } catch (ex) {
                console.log("🚀 ~ file: KeHoach.js ~ line 57 ~ ex", ex);
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            } finally {
                this.stopLoading();
            }
        },
        async LayDanhSachDoiXeDangKyTrenTuyen(store, { IdDnvtTuyen }) {
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachDoiXeDangKyTrenTuyen"
                    ),
                    {
                        idDnvtTuyen: IdDnvtTuyen,
                    }
                ).Get();
                if (resp.Data.status === true) {
                    let data = resp.Data.data || [];
                    store.state.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen =
                        data;
                } else {
                    store.state.ThongTinKyLenh.DanhSachDoiXeDangKyTrenTuyen =
                        [];
                }
                return resp.Data;
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async LayDanhSachXeVaDoiXeDangKyHoatDongTrenTuyen(
            store,
            { IdDnvtTuyen }
        ) {
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t(
                        "urlDNQuanLyLenh.DanhSachXeVaDoiXeDangKyHoatDongTrenTuyen"
                    ),
                    { IdDnvtTuyen: IdDnvtTuyen }
                ).Get();
                if (resp.Data.status) {
                    store.state.ThongTinKyLenh.DanhSachXe = resp.Data.data;
                }
                return resp;
            } catch (error) {
                console.log(
                    "🚀 ~ LayDanhSachXeVaDoiXeDangKyHoatDongTrenTuyen ~ error:",
                    error
                );
            }
        },
        async LayGoiYThongTinKyLenh(
            store,
            {
                IdDoiXe,
                IdNhaXe,
                IdDnvtTuyen,
                IdBenXuatPhat,
                IdDnvtXe,
                GioXuatBen,
            }
        ) {
            if (!IdDoiXe || !IdDnvtTuyen || !IdBenXuatPhat || !IdDnvtXe) {
                return;
            }
            try {
                let rs = await this.$Events.RequestGet({
                    ServicesBE: "QuanLyLenh",
                    url: this.$i18n.global.t(
                        "urlDNQuanLyLenh.GoiYThongTinKyLenh"
                    ),
                    dataGet: {
                        IdDoiXe: IdDoiXe || null,
                        IdNhaXe: IdNhaXe || null,
                        IdDnvtTuyen: IdDnvtTuyen || null,
                        IdBenXuatPhat: IdBenXuatPhat || null,
                        IdDnvtXe: IdDnvtXe || null,
                        GioXuatBen: GioXuatBen ? new Date(GioXuatBen) : null,
                    },
                    msgSuccess: "",
                    msgError: "Lấy thông tin gợi ý ký lệnh thất bại!",
                    msgCatch: "Lấy thông tin gợi ý ký lệnh thất bại!",
                });
                if (rs.status && rs.data) {
                    let data = rs.data;
                    store.state.ThongTinKyLenh.ThongTinChung.PhuXe = data.PhuXe;
                    let DanhSachIdLaiXe = JSON.parse(data.ID_LaiXes);
                    store.state.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh =
                        store.state.ThongTinKyLenh.ThongTinChung
                            .DanhSachLaiXeKyLenh || [];
                    let DanhSachLaiXeDangChon =
                        store.state.ThongTinKyLenh.ThongTinChung
                            .DanhSachLaiXeKyLenh;
                    if (DanhSachLaiXeDangChon.length == 0) {
                        DanhSachIdLaiXe.forEach((e) => {
                            let selectData = JSON.parse(
                                JSON.stringify(
                                    store.state.ThongTinKyLenh.DanhSachTatCaLaiXe.find(
                                        (x) => x.IdDnvtLaiXe == e
                                    )
                                )
                            );
                            store.state.ThongTinKyLenh.ThongTinChung.DanhSachLaiXeKyLenh.push(
                                selectData
                            );
                        });
                    } else {
                        let checkDaCoLaiXeTrongDanhSachLaiXeDangChon = true;
                        DanhSachIdLaiXe.forEach((e) => {
                            let check = DanhSachLaiXeDangChon.find(
                                (lx) => lx.IdDnvtLaiXe == e
                            );
                            if (!check) {
                                checkDaCoLaiXeTrongDanhSachLaiXeDangChon = false;
                            }
                        });
                        if (!checkDaCoLaiXeTrongDanhSachLaiXeDangChon) {
                            store.state.ThongTinKyLenh.DanhSachIdLaiXeGoiY =
                                DanhSachIdLaiXe;
                            this.emitter.emit("CapNhatThongTinLaiXeGoiY");
                        }
                    }
                    if (!GioXuatBen) {
                        let rsHour =
                            data.GioXuatBen.Hour < 10
                                ? "0" + data.GioXuatBen.Hour
                                : data.GioXuatBen.Hour;
                        let rsMinute =
                            data.GioXuatBen.Minute < 10
                                ? "0" + data.GioXuatBen.Minute
                                : data.GioXuatBen.Minute;
                        let rsGioXB = rsHour + ":" + rsMinute;
                        let itemGioXBKeHoach =
                            store.state.ThongTinKyLenh.DanhSachGioXBKeHoach.find(
                                (e) =>
                                    rsGioXB ==
                                    this.$Helper.formatTime(e.GioXuatBen)
                            );
                        if (itemGioXBKeHoach) {
                            store.state.ThongTinKyLenh.ThongTinChung.KeHoach =
                                itemGioXBKeHoach;
                        } else {
                            store.state.ThongTinKyLenh.ThongTinChung.GioXBKhac =
                                this.$Helper.getUTCDateFromTimeString(rsGioXB);
                        }
                    }
                }
            } catch (error) {
                console.log("🚀 ~ error:", error);
            }
        },
        async LayCauHinhSuDung(store) {
            let rs = await this.$Core.Api.QuanLyLenh(
                this.$i18n.global.t("urlDNQuanLyLenh.LayCauHinhSuDung")
            ).Get();
            if (rs.Data.errorCode === 0) {
                store.state.DanhSachCauHinhSuDung = rs.Data.data;
            }

            return rs.Data;
        },
    },
};
