export default {
    namespaced: true,
    state: () => ({
        resetPage: true,
        DanhSachLoaiLenh: [],
        KyLenhTuyChon_ThongTinChung: {
            LoaiLenhVanChuyen: 1,
            ID_DnvtXe: null,
            ID_DnvtXe_DuocDiThay: null,
            DanhSachLoTrinh: [],
            TenTuyen: null,
            ID_DnvtTuyen: null,
            HanPhuHieuTuyen: null,
            HanBaoHiemXe: null,
            HanDangKiemXe: null,
            IdNhaXe: null,
            KyTuNgay: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                0
            ),
            KyDenNgay: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                0
            ),
            HanBienNhanTheChap: null,
            TuDongKyLenhKhiCanBoXacNhanKiemTraXe: true,
            KyDienTu: true,
            BienKiemSoat: null,
            LuuChuyenDiDuKien: false,
        },
        KyLenhTuyChon_DanhSachPhuongTien: [],
        KyLenhTuyChon_DanhSachTuyenVanChuyen: [],
        KyLenhTuyChon_DanhSachLaiXe: [],
        KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon: [],
        KyLenhTuyChon_ThongTinChieuDi: {
            GioXB: [],
            PhuXe: null,
        },
        KyLenhTuyChon_ThongTinChieuVe: {
            GioXB: [],
            PhuXe: null,
        },
        KyLenhTuyChon_DanhSachLaiXeChieuDi: [null, null, null],
        KyLenhTuyChon_DanhSachLaiXeChieuVe: [null, null, null],
        KyLenhTuyChon_DanhSachNgayKyLenh: [],
        KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi: [],
        // KyLenhTuyChon_DanhSachNgayKyLenhChieuVe: [],
        KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe: [],
        KyLenhTuyChon_ThongTinSuaLaiXe: {
            TenLaiXe: null,
            IdLaiXe: null,
            IdDnvtLaiXe: null,
            HangGPLX: null,
            HanHieuLucGPLX: null,
            NgayKetThucHopDong: null,
            NgayBatDauHopDong: null,
        },
        DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
        resetTaoLenhTuyChon(state) {
            state.DanhSachLoaiLenh = [];
            state.KyLenhTuyChon_ThongTinChung = {
                LoaiLenhVanChuyen: 1,
                ID_DnvtXe: null,
                ID_DnvtXe_DuocDiThay: null,
                DanhSachLoTrinh: [],
                TenTuyen: null,
                ID_DnvtTuyen: null,
                HanPhuHieuTuyen: null,
                HanBaoHiemXe: null,
                HanDangKiemXe: null,
                IdNhaXe: null,
                KyTuNgay: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    0
                ),
                KyDenNgay: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    0
                ),
                HanBienNhanTheChap: null,
                TuDongKyLenhKhiCanBoXacNhanKiemTraXe: true,
                KyDienTu: true,
                BienKiemSoat: null,
                LuuChuyenDiDuKien: false,
            };
            state.KyLenhTuyChon_DanhSachPhuongTien = [];
            state.KyLenhTuyChon_DanhSachTuyenVanChuyen = [];
            state.KyLenhTuyChon_DanhSachLaiXe = [];
            state.KyLenhTuyChon_DanhSachPhuongTienTaoLenhTuyChon = [];
            state.KyLenhTuyChon_ThongTinChieuDi = {
                GioXB: [],
                PhuXe: null,
            };
            state.KyLenhTuyChon_ThongTinChieuVe = {
                GioXB: [],
                PhuXe: null,
            };
            state.KyLenhTuyChon_DanhSachLaiXeChieuDi = [null, null, null];
            state.KyLenhTuyChon_DanhSachLaiXeChieuVe = [null, null, null];
            state.KyLenhTuyChon_DanhSachNgayKyLenh = [];
            state.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuDi = [];
            state.KyLenhTuyChon_DanhSachNgayKyLenhDangChonChieuVe = [];
            state.KyLenhTuyChon_ThongTinSuaLaiXe = {
                TenLaiXe: null,
                IdLaiXe: null,
                IdDnvtLaiXe: null,
                HangGPLX: null,
                HanHieuLucGPLX: null,
                NgayKetThucHopDong: null,
                NgayBatDauHopDong: null,
            };
            state.DanhSachCauHinhDuocPhepChinhSuaKhiKyLenh = [];
        },
    },
    getters: {
        CheckDuDieuKienThongTinXe(state) {
            let data = state.KyLenhTuyChon_ThongTinChung;
            if (!data.HanBaoHiemXe || !data.HanDangKiemXe) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanBaoHiemXe,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.HanDangKiemXe,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanBaoHiemXe,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.HanDangKiemXe,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }

            return "DuDieuKien";
        },
        CheckDuDieuKienLaiXeDi_1(state) {
            let data = state.KyLenhTuyChon_DanhSachLaiXeChieuDi[0];
            if (!data) {
                return "DuDieuKien";
            }

            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }
            return "DuDieuKien";
        },
        CheckDuDieuKienLaiXeDi_2(state) {
            let data = state.KyLenhTuyChon_DanhSachLaiXeChieuDi[1];
            if (!data) {
                return "DuDieuKien";
            }

            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }
            return "DuDieuKien";
        },
        CheckDuDieuKienLaiXeDi_3(state) {
            let data = state.KyLenhTuyChon_DanhSachLaiXeChieuDi[2];
            if (!data) {
                return "DuDieuKien";
            }

            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }
            return "DuDieuKien";
        },
        CheckDuDieuKienLaiXeVe_1(state) {
            let data = state.KyLenhTuyChon_DanhSachLaiXeChieuVe[0];
            if (!data) {
                return "DuDieuKien";
            }

            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }
            return "DuDieuKien";
        },
        CheckDuDieuKienLaiXeVe_2(state) {
            let data = state.KyLenhTuyChon_DanhSachLaiXeChieuVe[1];
            if (!data) {
                return "DuDieuKien";
            }

            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }
            return "DuDieuKien";
        },
        CheckDuDieuKienLaiXeVe_3(state) {
            let data = state.KyLenhTuyChon_DanhSachLaiXeChieuVe[2];
            if (!data) {
                return "DuDieuKien";
            }

            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 0,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 0,
                })
            ) {
                return "HetHan";
            }
            if (
                checkNgayKhongDuDieuKien({
                    date: data.HanGiayPhep,
                    soNgay: 15,
                }) ||
                checkNgayKhongDuDieuKien({
                    date: data.NgayKetThucHopDong,
                    soNgay: 15,
                })
            ) {
                return "GanHetHan";
            }
            return "DuDieuKien";
        },
    },

    actions: {
        async Get_DanhSachLoaiLenh(store) {
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.LayDanhSachLoaiLenh")
                ).Get();
                if (resp.Data.status) {
                    let result = resp.Data.data;
                    store.state.DanhSachLoaiLenh = result || [];

                    return result;
                } else {
                    return { Store: [] };
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: LenhTuyChon.js:155 ~ Get_DanhSachLoaiLenh ~ error:",
                    error
                );
            }
        },
        async Get_DanhSachLaiXeTaoLenhTuyChon(store, key, query) {
            let resp = await this.$Core.Api.QuanLyThongTinLenh(
                this.$i18n.global.t("urlQLTTLenh.DanhSachLaiXeTaoLenhTuyChon")
            ).Post({ skip: 0, take: 0 });
            if (resp.StatusCode === 200) {
                let result = resp.Data.data.data;
                return { Store: result };
            } else {
                return { Store: [] };
            }
        },
        async Get_DanhSachPhuongTienTaoLenhTuyChon(store, { query }, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachPhuongTienTaoLenhTuyChon"
                            )
                        ).Post({
                            loadOptions,
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            // set(
                            //     store,
                            //     "KyLenhTuyChon_DanhSachPhuongTien",
                            //     result.data || []
                            // );
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    }
                },
                key || null,
                async (key) => {
                    try {
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachPhuongTienTaoLenhTuyChon"
                            )
                        ).Post({
                            loadOptions: {
                                filter: ["ID_DnvtXe", "=", key],
                            },
                            custom: query,
                        });
                        if (resp.StatusCode === 200) {
                            let data =
                                resp.Data.data?.data.length > 0
                                    ? resp.Data.data.data[0]
                                    : {};
                            return data;
                        } else {
                            return {};
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                }
            );
            return load;
        },
        async Get_DanhSachLuongTuyen(store, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        let resp = await this.$Core.Api.QuanLyThongTinLenh(
                            this.$i18n.global.t(
                                "urlQLTTLenh.DanhSachTuyenTuChon"
                            )
                        ).Post(loadOptions);
                        if (resp.StatusCode === 200) {
                            let result = resp.Data.data;
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        return [];
                    }
                },
                key || null
            );
            return load;
        },
        async KyLenhTuyChon({ commit }, data) {
            try {
                let resp = await this.$Core.Api.QuanLyLenh(
                    this.$i18n.global.t("urlDNQuanLyLenh.KyLenhTuyChon")
                ).Post(data);
                if (resp.Data.errorCode == 0) {
                    return resp.Data || {};
                }
                return resp.Data;
            } catch (ex) {
                console.log(
                    "🚀 ~ file: LenhTuyChon.js:428 ~ KyLenhTuyChon ~ ex:",
                    ex
                );
            }
        },
    },
};

function checkNgayKhongDuDieuKien({ date, soNgay }) {
    let dateCheck = new Date();
    dateCheck.setDate(dateCheck.getDate() + soNgay);
    dateCheck.setHours(0);
    dateCheck.setMinutes(0);
    dateCheck.setSeconds(0);
    dateCheck.setMilliseconds(0);
    date = new Date(date);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(0);
    let soNgayTinhToan = (date - dateCheck) / 1000 / 24 / 60 / 60;
    return soNgayTinhToan < 0;
}
