export default {
    namespaced: true,
    state: () => ({
        DanhSachLoaiBen: [],
        DanhSachBenXeDi: [],
        DanhSachBenXeDen: [],
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async Get_DanhSachLoaiBen(store) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachLoaiBen")
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    store.state.DanhSachLoaiBen = result || [];
                } else {
                    store.state.DanhSachLoaiBen = [];
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async Get_DanhSachBenXeTheoTinh(store, data) {
            store;
            try {
                this.startLoading();
                let resp = await this.$Core.Api.QuanLyThongTinLenh(
                    this.$i18n.global.t("urlQLTTLenh.DanhSachBenXeTheoTinh"),
                    {
                        idTinhThanh: data.value,
                    }
                ).Get();
                this.stopLoading();
                let result = resp.Data.data;
                if (resp.StatusCode === 200) {
                    store.state[data.key] = result || [];
                } else {
                    store.state[data.key] = [];
                }
                return result || [];
            } catch (ex) {
                this.stopLoading();
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
    },
};
