export default {
    namespaced: true,
    state: () => ({
        TuNgay: new Date(),
        DenNgay: new Date(),
        ChuyenDiCuaLaiXe: {
            TuNgay: new Date(),
            DenNgay: new Date(),
            DanhSachTieuChiSapXep: [
                {
                    id: 1,
                    text: "Giờ XB",
                    order: 0,
                    truongSapXep: "thoiGianXuatBen",
                    kieuSapXep: "",
                },
                {
                    id: 2,
                    text: "BKS",
                    order: 0,
                    truongSapXep: "bienKiemSoat",
                    kieuSapXep: "",
                },
                {
                    id: 3,
                    text: "Tuyến",
                    order: 0,
                    truongSapXep: "tuyenVanChuyen",
                    kieuSapXep: "",
                },
            ],
        },
        ChuyenDiDangChon: null,
    }),

    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async Get_DanhSachChuyenDiDuocUyThac(store, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        loadOptions.skip = 0;
                        loadOptions.take = 0;
                        // let filter = [];
                        // if (loadOptions.searchValue) {
                        //     let listFieldSearch = [
                        //         "bienKiemSoat",
                        //         "tuyenVanChuyen",
                        //         "gioXuatBen"
                        //     ];
                        //     filter = listFieldSearch
                        //         .map(e => [e, "contains", loadOptions.searchValue])
                        //         .reduce(
                        //             (filter, x, i, a) => (
                        //                 filter.push(x),
                        //                 i < a.length - 1 ? filter.push("or") : !0,
                        //                 filter
                        //             ),
                        //             []
                        //         );
                        // }
                        // loadOptions.filter = filter || null;
                        let resp = await this.$Core.Api.ChuyenDi(
                            this.$i18n.global.t(
                                "url.DanhSachChuyenDiDuocUyThacTheoKhoangThoiGian"
                            )
                        ).Post({
                            args: {
                                tuNgay: this.$Helper.getStartDay(
                                    store.state.TuNgay
                                ),
                                denNgay: this.$Helper.getEndDay(
                                    store.state.DenNgay
                                ),
                            },
                            loadOptions,
                        });
                        if (resp.Data.status) {
                            if (resp.Data.data) {
                                resp.Data.data.data.unshift({
                                    tuyenVanChuyen: "Tất cả",
                                    idChuyenDi: 9999,
                                });
                            }
                            let result =
                                resp.Data.data && resp.Data.data.data
                                    ? resp.Data.data
                                    : [];

                            this.stopLoading();
                            return result;
                        } else {
                            this.stopLoading();
                            return [];
                        }
                    } catch (ex) {
                        this.stopLoading();
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async Get_BaoCaoNhanVienBanVe(store, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        let resp = await this.$Core.Api.BaoCao(
                            this.$i18n.global.t(
                                "url.BaoCaoNhanVienBanVeChoDoanhNghiep"
                            )
                        ).Post({
                            loadOptions,
                            args: {
                                tuNgay: this.$Helper.getStartDay(
                                    store.state.TuNgay
                                ),
                                denNgay: this.$Helper.getEndDay(
                                    store.state.DenNgay
                                ),
                                idChuyenDi:
                                    store.state.ChuyenDiDangChon == 9999
                                        ? null
                                        : store.state.ChuyenDiDangChon,
                            },
                        });
                        if (resp.Data.status) {
                            let result =
                                resp.Data.data && resp.Data.data.data
                                    ? resp.Data.data
                                    : [];
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },

        async Get_DanhSachChuyenDiCuaLaiXe(store, key) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        let DanhSachTieuChiSapXep =
                            store.state.ChuyenDiCuaLaiXe.DanhSachTieuChiSapXep.filter(
                                (e) => e.order != 0
                            );
                        if (DanhSachTieuChiSapXep.length > 0) {
                            loadOptions.sort = [
                                {
                                    selector:
                                        DanhSachTieuChiSapXep[0].truongSapXep,
                                    desc:
                                        DanhSachTieuChiSapXep[0].kieuSapXep ==
                                        "DESC",
                                },
                            ];
                        }
                        let resp = await this.$Core.Api.BaoCao(
                            this.$i18n.global.t("url.BaoCaoChuyenDiCuaLaiXe")
                        ).Post({
                            loadOptions,
                            args: {
                                tuNgay: this.$Helper.getStartDay(
                                    store.state.ChuyenDiCuaLaiXe.TuNgay
                                ),
                                denNgay: this.$Helper.getEndDay(
                                    store.state.ChuyenDiCuaLaiXe.DenNgay
                                ),
                            },
                        });
                        if (resp.Data.status) {
                            let result =
                                resp.Data.data && resp.Data.data.data
                                    ? resp.Data.data
                                    : [];
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                key || null
            );
            return load;
        },
        async Get_BaoCaoHanhKhachMuaVeTheoChuyenDiCuaLaiXe(store, idChuyenDi) {
            store;
            var load = this.$Helper.DevExDataSourceCustom(
                async (loadOptions) => {
                    try {
                        let resp = await this.$Core.Api.BaoCao(
                            this.$i18n.global.t(
                                "url.BaoCaoHanhKhachMuaVeTheoChuyenDiCuaLaiXe"
                            )
                        ).Post({
                            loadOptions,
                            args: idChuyenDi,
                        });
                        if (resp.Data.status) {
                            let result =
                                resp.Data.data && resp.Data.data.data
                                    ? resp.Data.data
                                    : [];
                            return result;
                        } else {
                            return [];
                        }
                    } catch (ex) {
                        console.log("🚀 ~ ex", ex);
                        if (ex.message == "Network Error")
                            throw Error("Không kết nối được đến máy chủ!");
                        throw ex;
                    }
                },
                null
            );
            return load;
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
function unset(store, key) {
    store.commit("Unset", {
        key: key,
    });
}
