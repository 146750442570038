export default {
    namespaced: true,
    state: () => ({}),

    mutations: {},

    actions: {
        DanhSachHinhThucChayTheoLoaiLich(store) {
            let arrLoaiLich = {};
            arrLoaiLich[this.$i18n.global.t("HinhThucChay.ThangDuong")] = {
                HinhThucChay: "TheoNgay",
                IsLichAm: false,
            };
            arrLoaiLich[this.$i18n.global.t("HinhThucChay.ThangAm")] = {
                HinhThucChay: "TheoNgay",
                IsLichAm: true,
            };
            arrLoaiLich[this.$i18n.global.t("HinhThucChay.Tuan")] = {
                HinhThucChay: "TheoTuan",
                IsLichAm: false,
            };
            arrLoaiLich[this.$i18n.global.t("HinhThucChay.ChuKy")] = {
                HinhThucChay: "TheoChuKy",
                IsLichAm: false,
            };
            return arrLoaiLich;
        },
        LaySoNgayTrongKhoangThoiGian(store, { TuNgay, DenNgay }) {
            const startDate = this.$Helper.Common.getStartDay(TuNgay);
            const endDate = this.$Helper.Common.getStartDay(DenNgay);
            const miliTime = endDate.getTime() - startDate.getTime();
            const soNgay = miliTime / (1000 * 3600 * 24) + 1;
            return soNgay;
        },
        async KiemTraTonTaiNgayKeHoach(
            store,
            {
                NgayKiemTra = new Date(),
                KeHoach = {
                    IdKeHoach: null,
                    GioXuatBen: null,
                    LoaiLichChay: null,
                    CacNgayChay: [],
                    NgayBatDauChuKy: new Date(),
                    ChuKyNgay: null,
                },
            }
        ) {
            if (
                KeHoach.LoaiLichChay ==
                this.$i18n.global.t("HinhThucChay.ThangDuong")
            ) {
                let tmpDate = new Date(NgayKiemTra).getDate();
                return KeHoach.CacNgayChay.some((e) => e == tmpDate);
            } else if (
                KeHoach.LoaiLichChay == this.$i18n.global.t("HinhThucChay.ThangAm")
            ) {
                let NgayAm = null;
                let arrTemp =
                    this.$Helper.Common.formatDate(NgayKiemTra).split("/");
                let arrNgayAm = this.$Helper
                    .getLunarDateText(
                        parseInt(arrTemp[0]),
                        parseInt(arrTemp[1]),
                        parseInt(arrTemp[2])
                    )
                    .split("/");
                if (arrNgayAm.length == 2) {
                    NgayAm = arrNgayAm[0];
                }
                return KeHoach.CacNgayChay.some((e) => e == NgayAm);
            } else if (
                KeHoach.LoaiLichChay == this.$i18n.global.t("HinhThucChay.Tuan")
            ) {
                let tmpDate = new Date(NgayKiemTra).getDay() + 1;
                return KeHoach.CacNgayChay.some((e) => e == tmpDate);
            } else if (
                KeHoach.LoaiLichChay == this.$i18n.global.t("HinhThucChay.ChuKy")
            ) {
                const startDate = this.$Helper.Common.getStartDay(
                    KeHoach.NgayBatDauChuKy
                );
                const endDate = this.$Helper.Common.getStartDay(NgayKiemTra);
                if (startDate > endDate) {
                    return false;
                } else {
                    const miliTime = endDate.getTime() - startDate.getTime();
                    const soNgay = miliTime / (1000 * 3600 * 24);
                    return (soNgay / KeHoach.ChuKyNgay) % 1 == 0;
                }
            }
            return true;
        },
    },
};
