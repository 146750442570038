export default {
    namespaced: true,
    state: () => ({
        chinhSuaSoatVe: false,
        resetSoDoCho: 0,
        reloadSoDoCho: 0,

        choDaChon: [],
        chuyenDiGanDay: null,
        componentKey: 0
    }),
    actions: {
        async LayDanhSachHangHoaTheoChuyenDi(state, query) {
            if (!query.idChuyenDi) {
                throw new Error("Không có guidChuyenDi");
            }
            let rs = await $Core.Api.HangHoa(
                this.$i18n.global.t("urlHangHoa.DanhSachHangHoaTheoChuyenDi"),
                query
            ).Get();
            return rs;
        },
        async NhanHangHoa(state, query) {
            this.startLoading();
            let form = new FormData();
            form.append("idChuyenDi", query.idChuyenDi);
            form.append("tongTien", query.tongTien);
            form.append("soDienThoaiNhan", query.soDienThoaiNhan);
            form.append("soDienThoaiGui", query.soDienThoaiGui);
            form.append("idDiemNhan", query.idDiemNhan);
            form.append("tenDiemNhan", query.tenDiemNhan);
            form.append("thoiGianGiaoHangDuKien", query.thoiGianGiaoHangDuKien);
            form.append("daTraCuoc", query.daTraCuoc);
            form.append("toaDoGuiHang", query.toaDoGuiHang);
            query.danhSachHinhAnh.forEach(e => {
                form.append("danhSachHinhAnh", e);
            });

            let rs = await $Core.Api.HangHoa(
                this.$i18n.global.t("urlHangHoa.NhanHangHoa")
            ).Post(form);
            this.stopLoading();
            return rs;
        },
        async TraHangHoa(state, query) {
            this.startLoading();
            let form = new FormData();
            form.append("idNhatKy", query.idNhatKy);
            form.append("toaDo", query.toaDo);
            query.danhSachHinhAnh.forEach(e => {
                form.append("danhSachHinhAnh", e);
            });
            let rs = await $Core.Api.HangHoa(
                this.$i18n.global.t("urlHangHoa.TraHangHoa")
            ).Post(form);
            this.stopLoading();
            return rs;
        },
        async LayChiTietHang(state, query) {
            let rs = await $Core.Api.HangHoa(
                this.$i18n.global.t("urlHangHoa.LayChiTietHangHoa"),
                {
                    idNhatKy: query.idNhatKy
                }
            ).Get();
            return rs;
        },
        async LayDanhSachTrangThaiHang(state, guidChuyenDi) {
            let rs = await $Core.Api.HangHoa(
                this.$i18n.global.t("urlHangHoa.DanhSachTrangThaiVanChuyen"),
                {
                    idChuyenDi: guidChuyenDi
                }
            ).Get();
            return rs;
        },
        async CapNhatHinhAnhVanChuyenHang(state, query) {
            let rs = await $Core.Api.HangHoa(
                this.$i18n.global.t("urlHangHoa.CapNhatHinhAnhVanChuyenHang")
            ).Post(query);
            return rs;
        }
    }
};
