export default {
    namespaced: true,
    state: () => ({}),
    getters: {},
    mutations: {
        Set(state, { key, data }) {
            state[key] = data;
        },
        Unset(state, { key }) {
            delete state[key];
        },
    },

    actions: {
        async CapNhatFcmToken(store) {
            // let testFCMTOk = document.getElementById("testFCMTOk");
            // testFCMTOk.innerText += `\nvào1`;
            localStorage.setItem("DaSetFcmToken", false);
            if (navigator && navigator.frontendVersion) {
                // testFCMTOk.innerText += `\nvào 2`;
                this.startLoading();
                let FcmToken =
                    await SonPhat.getFirebaseCloudMessagingRegistrationToken();
                // testFCMTOk.innerText += `\n` + FcmToken;
                try {
                    let rs = await this.$Core.Api.ThongBao(
                        this.$i18n.global.t(
                            "urlThongBao.ThucHienCapNhatFcmToken"
                        )
                    ).Post({
                        fcmToken: FcmToken,
                    });
                    if (rs.Data.status) {
                        localStorage.setItem("DaSetFcmToken", true);
                    } else {
                        setTimeout(() => {
                            store.dispatch("CapNhatFcmToken");
                        }, 300000);
                    }
                    // testFCMTOk.innerText += `\n` + JSON.stringify(rs.Data);
                } catch (error) {
                    console.log("🚀 ~ CapNhatFcmToken ~ error:", error);
                    setTimeout(() => {
                        store.dispatch("CapNhatFcmToken");
                    }, 300000);
                } finally {
                    this.stopLoading();
                }
            }
        },
        async KiemTraVaCapNhatFcmToken(store) {
            try {
                let DaSetFcmToken = localStorage.getItem("DaSetFcmToken");
                if (DaSetFcmToken != "true") {
                    store.dispatch("CapNhatFcmToken");
                }
            } catch (error) {
                console.log("🚀 ~ KiemTraVaCapNhatFcmToken ~ error:", error);
            }
        },
        async LayDanhSachThongBao(store, { skip, query, key }) {
            store;
            try {
                let loadOptions = {
                    skip: skip,
                    take: 10,
                    sort: [
                        {
                            selector: "thoiGianThongBao",
                            desc: true,
                        },
                    ],
                };
                let resp = await this.$Core.Api.ThongBao(
                    this.$i18n.global.t("urlThongBao.DanhSachThongBao")
                ).Post(loadOptions);
                if (resp.Data.status) {
                    let result = resp.Data.data?.data || [];
                    return result;
                } else {
                    return [];
                }
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                return [];
            }
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
