import Devextreme from "@sonphat/devextreme";
export default function Helper({ $Core, emitter, $store }) {
    Devextreme.$Core = $Core;
    this.isKhoaLuong = {};

    this.ThongBaoToast = (type, msg) => {
        let ParamsError = {
            isVisible: true,
            message: msg,
            type: type,
        };
        emitter.emit("onToast", ParamsError);
    };

    this.RequestPost = async ({
        keyRequest = "api",
        ServicesBE = "Make",
        url = "",
        dataPost = null,
        msgSuccess = null,
        msgError = null,
        msgCatch = null,
        typeNotificationSuccess = "Toast",
        typeNotificationError = "Popup",
    }) => {
        // Bắt buộc truyền thông báo
        if (!msgCatch || !msgError) {
            $Helper.ThongBaoToast(
                "error",
                "Chưa thể gửi request! Vui lòng kiểm tra lại!"
            );
        }
        if (this.isKhoaLuong[keyRequest]) return;
        $store.startLoading();
        this.isKhoaLuong[keyRequest] = "pending...";
        try {
            let rs = await $Core.Requests.Post({
                ServicesBE,
                url,
                dataPost,
            });

            if (
                (rs.hasOwnProperty("status") && rs.status) ||
                (rs.hasOwnProperty("State") && rs.State == "Success")
            ) {
                if (msgSuccess) {
                    if (typeNotificationSuccess == "Toast") {
                        $Helper.ThongBaoToast("success", msgSuccess);
                    } else if (typeNotificationSuccess == "Popup") {
                        $Helper.ThongBaoPopup({
                            type: "Success",
                            message_title: msgSuccess,
                        });
                    }
                }
            } else {
                if (typeNotificationError == "Popup") {
                    $Helper.ThongBaoPopup({
                        type: "Error",
                        message_title: msgError,
                        message: rs.message || rs.Message || "",
                    });
                } else if (typeNotificationError == "Toast") {
                    $Helper.ThongBaoToast(
                        "error",
                        msgError + " " + (rs.message || rs.Message || "")
                    );
                }
            }
            return rs;
        } catch (error) {
            console.log("🚀 ~ error:", error);
            if (typeNotificationError == "Popup") {
                $Helper.ThongBaoPopup({
                    type: "Error",
                    message_title: msgCatch,
                });
            } else if (typeNotificationError == "Toast") {
                $Helper.ThongBaoToast("error", msgCatch);
            }
            return {
                status: false,
                data: null,
                errorCode: 0,
                message: "Catch!",
            };
        } finally {
            this.isKhoaLuong[keyRequest] = undefined;
            $store.stopLoading();
        }
    };
    this.RequestGet = async ({
        ServicesBE = "Make",
        url = "",
        dataGet = null,
        msgSuccess = null,
        msgError = null,
        msgCatch = null,
    }) => {
        $store.startLoading();
        try {
            let rs = await $Core.Requests.Get({
                ServicesBE,
                url,
                dataGet,
            });
            if (
                (rs.hasOwnProperty("status") && rs.status) ||
                (rs.hasOwnProperty("State") && rs.State == "Success")
            ) {
                if (msgSuccess) {
                    $Helper.ThongBaoToast("success", msgSuccess);
                }
            } else {
                if (msgError) {
                    $Helper.ThongBaoToast(
                        "error",
                        msgError + " " + (rs.message || rs.Message || "")
                    );
                }
            }
            return rs;
        } catch (error) {
            console.log("🚀 ~ error:", error);
            if (msgCatch) {
                $Helper.ThongBaoToast("error", msgCatch);
            }
            return {
                status: false,
                data: null,
                errorCode: 0,
                message: msgCatch || "Catch!",
            };
        } finally {
            $store.stopLoading();
        }
    };

    this.StoreRequestPost = async ({
        keyRequest = "api",
        dispatch = "Common/RequestPost",
        ServicesBE = "Make",
        url = "",
        dataPost = null,
        msgSuccess = null,
        msgError = null,
        msgCatch = null,
    }) => {
        // Bắt buộc truyền thông báo
        if (!msgCatch || !msgError) {
            $Helper.ThongBaoToast(
                "error",
                "Chưa thể gửi request! Vui lòng kiểm tra lại"
            );
        }
        if (this.isKhoaLuong[keyRequest]) return;
        $store.startLoading();
        this.isKhoaLuong[keyRequest] = "pending...";
        try {
            let rs = await $store.dispatch(dispatch, {
                ServicesBE,
                url,
                dataPost,
            });

            if (
                (rs.hasOwnProperty("status") && rs.status) ||
                (rs.hasOwnProperty("State") && rs.State == "Success")
            ) {
                $Helper.ThongBaoToast("success", msgSuccess);
            } else {
                $Helper.ThongBaoToast(
                    "error",
                    msgError + (rs.message || rs.Message || "")
                );
            }
            return rs;
        } catch (error) {
            console.log("🚀 ~ error:", error);
            $Helper.ThongBaoToast("error", msgCatch);
            return {
                status: false,
                data: null,
                errorCode: 0,
                message: "Catch!",
            };
        } finally {
            this.isKhoaLuong[keyRequest] = undefined;
            $store.stopLoading();
        }
    };
    this.StoreRequestGet = async ({
        ServicesBE = "Make",
        dispatch = "Common/RequestGetData",
        url = "",
        dataGet = null,
        msgSuccess = null,
        msgError = null,
        msgCatch = null,
    }) => {
        $store.startLoading();
        try {
            let rs = await $store.dispatch(dispatch, {
                ServicesBE,
                url,
                dataGet,
            });
            if (
                (rs.hasOwnProperty("status") && rs.status) ||
                (rs.hasOwnProperty("State") && rs.State == "Success")
            ) {
                if (msgSuccess) {
                    $Helper.ThongBaoToast("success", msgSuccess);
                }
            } else {
                if (msgError) {
                    $Helper.ThongBaoToast(
                        "error",
                        msgError + " " + (rs.message || rs.Message || "")
                    );
                }
            }
            return rs;
        } catch (error) {
            console.log("🚀 ~ error:", error);
            if (msgCatch) {
                $Helper.ThongBaoToast("error", msgCatch);
            }
            return {
                status: false,
                data: null,
                errorCode: 0,
                message: "Catch!",
            };
        } finally {
            $store.stopLoading();
        }
    };

    this.Common = Devextreme;
}
